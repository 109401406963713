
import {Injectable} from '@angular/core';
import { environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ProfileService {
  public baseURL = environment.config.apiKey;
  constructor(private http: HttpClient) {}

  getProfile(): Observable<any> {
    const headers = {
      // headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.get(this.baseURL + '/student/profile/get',  headers);
  }
  updateProfile(data: any): Observable<any> {
    const headers = {
      // headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.post(this.baseURL + '/student/profile/update',data,  headers);
  }

  getNationality(): Observable<any> {
    const headers = {
      // headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.get(this.baseURL + '/student/metadata/nationality',  headers);
  }

  getCountry(): Observable<any> {
    const headers = {
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.get(this.baseURL + '/student/metadata/country',  headers);
  }
  getTimeZone(code): Observable<any> {
    const headers = {
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.get(this.baseURL + '/student/metadata/timezone/'+code,  headers);
  }
  uploadProfileImage(img:any): Observable<any>{
    return this.http.post(this.baseURL + '/student/profile/upload_profile_image',img);
  }
  logOutH5P(tokenKey):Observable<any>{
    const headers = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
    };
    const tokenId = 'token=' + tokenKey;
    return this.http.post(environment.config.h5plogin+'logout',tokenId,headers);
  }

  getNotification(): Observable<any> {
    
    const headers = {
      // headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
      headers: new HttpHeaders().set('Accept-Language', 'english')
    };
    return this.http.get(this.baseURL + '/student/course/notifications',  headers);
  }
}

