import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { GroupClassComponent } from "./group-class.component";
import { StarRatingDirective } from "../../core/common-directive/star-rating.directive";
import { CalenderDirective } from "../../core/common-directive/calendar/calender.directive";
import { MyCourseComponent } from "./my-course/my-course.component";
import {
  MyActivityComponent,
  SafeHtmlPipe,
  SafeResourceUrl
} from "../my-activity/my-activity.component";
import { CommonDirectiveModule } from "../../core/common-directive/common.module";
import { NgxBraintreeModule } from "ngx-braintree";
import { NgxStripeModule } from "ngx-stripe";
import { IonicPageModule } from "ionic-angular";
import { PdfViewerModule } from 'ng2-pdf-viewer';

export const routes = [
  { path: "", component: GroupClassComponent, pathMatch: "full" }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    CommonDirectiveModule,
    NgxBraintreeModule,
    NgxStripeModule.forRoot("pk_live_k3hsCDHj4GEKT16EK1B4sNAF00vkmE7biT"),
    IonicPageModule.forChild(GroupClassComponent),
    PdfViewerModule
  ],
  declarations: [
    // StarRatingDirective,
    GroupClassComponent,
    CalenderDirective,
    MyCourseComponent,
    MyActivityComponent,
    SafeHtmlPipe,
    SafeResourceUrl
  ]
})
export class GroupClassModule {}
