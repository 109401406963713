import {
  Component,
  OnChanges,
  ViewEncapsulation,
  ViewChild,
  TemplateRef,
  ElementRef
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent
} from "angular-calendar";
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from "date-fns";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { GroupClassCommonDictionary } from "./group-class-common-dictionary";
import { GroupClassService } from "./group-class.service";
import { DashboadCommonDictionary } from "../dashboard/dashboard-common-dictionary";
// import {HttpErrorResponse} from '@angular/common/http';hided
import { ToastrService } from "ngx-toastr";
import { getResponseURL } from "@angular/http/src/http_utils";
import { GoogleAnalyticsService } from "../../core/common-service/google-analytics.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  FormGroupDirective,
  NgForm
} from "@angular/forms";
import { MyActivityService } from "../my-activity/my-activity.service";

import { Observable } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from "@angular/common/http";
import { StripeService } from "ngx-stripe";
import { Elements, Element as StripeElement } from "ngx-stripe";
import { IonicPage } from "ionic-angular";

import * as Stripe from "ngx-stripe";
import Swal from "sweetalert2";
import { DomSanitizer} from '@angular/platform-browser';
import { Pipe } from '@angular/core';
const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3"
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF"
  },
  yellow: {
    primary: "red",
    secondary: "#822b8a"
  }
};
declare var $: any;
@Pipe({ name: 'safe' })
@IonicPage({ name: "app-group-class" })
@Component({
  selector: "app-group-class",
  templateUrl: "./group-class.component.html",
  styleUrls: ["./group-class.component.scss", "./calendar.scss"],
  providers: [GroupClassService, MyActivityService],
  encapsulation: ViewEncapsulation.None
})
export class GroupClassComponent {
  public stripeTest: FormGroup;
  @ViewChild("modalContent") modalContent: TemplateRef<any>;

  elements: Elements;
  card: StripeElement;
  @ViewChild("card") cardRef: ElementRef;

  view: string = "month";
  viewDate: Date = new Date();
  selectedDate;
  public entries = [];
  public courseLevelList = [];
  public selectedEntry: { [key: string]: any } = {
    value: null,
    description: null
  };
  public levelId;
  public dashboardText = DashboadCommonDictionary;
  public courseList; // hold my Course Response
  public onGoingClassList; // hold ongoing response
  public discountList;
  public groupClassText = GroupClassCommonDictionary; // Group class Dictionarty for group class page
  public selectedItem;
  public available: any = "70";
  public myCourseTab = [
    { tabName: "Ongoing Course" },
    { tabName: "Completed Course" }
  ]; //,{"tabName":"Upcoming Session"} tabs 1) Ongoing Class ..2)Completed Class.. 3)Upcoming Class
  // public onGoingSectionTab=[{"tabName":"Upcoming Session"},{"tabName":"Completed Session"}];
  public onGoingSectionTab = [
    { tabName: "Completed Session" },
    { tabName: "Upcoming Session" }
  ];
  public selectedOngoingItem;
  score: number = 0;
  displayRatingScore = 0;
  selectedRating;
  public ratingMessage;
  public selectedRatingSlotId;
  public groupId; // hold if user select Group Type
  public tabType;
  public isOngoingTabSelected = false;
  public scheduleSessionClassList; // hold session upcoming Class list Response
  public groupClass = [{ myList: "3" }, { myList: "2" }, { myList: "0" }];
  public availableTimeSlotList; // hold available time slot
  public selectedOngoingCourse;
  public availableSlotResponseLength;
  public class_service_type;
  public selectedSlotId;
  public slotName;
  public reschduleTime;
  public oldSlotID;
  public currentSlotId;
  public forGroupSlotRescheduleConfirm = true;
  public slotChangeMessage; // msg for slot change private /semi private
  MessagecreateForm: FormGroup;
  public sharedNotes;
  public sharedNotesCount;
  public responsePayment;
  authToken;
  public showEventINnCalender; // hold calender Response
  events = [];
  public isEvent = false;
  public isViewActivity = false;
  public completedFlag = false;
  // public secretToken;
  public discountText = {
    discount_code: "",
    course_id: ""
  };  
  public pdfSrc;
  public final_amount = '';
  public total_amount='';
  enroll: boolean;
  constructor(
    public googleAnalyticsService: GoogleAnalyticsService,
    private _title: Title,
    private toastr: ToastrService,
    public groupClassService: GroupClassService,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    public myActivityService: MyActivityService,
    public http: HttpClient,
    private stripeService: StripeService,
    private domSanitizer: DomSanitizer
  ) {
    if (this.completedFlag == false) {
      this.selectedItem = this.myCourseTab[0];
    } else {
      this.selectedItem = this.myCourseTab[1];
    }
    this.getSelectedTabContainerResponse(this.selectedItem);
    let date = new Date();
    this.getCalendarSlots(date.getMonth() + 1, date.getFullYear());
    this._title.setTitle("Group Class");
    this.getRoutingParameers();
    this.mountCard();
    // this.stripeService.elements().subscribe(elements => {
    //   this.elements = elements;
    //   // Only mount the element the first time
    //   if (!this.card) {
    //     this.card = this.elements.create("card", {
    //       style: {
    //         base: {
    //           iconColor: "#666EE8",
    //           color: "#31325F",
    //           lineHeight: "40px",
    //           fontWeight: 300,
    //           fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //           fontSize: "18px",
    //           "::placeholder": {
    //             color: "#CFD7E0"
    //           }
    //         }
    //       }
    //     });
    //     this.card.mount(this.cardRef.nativeElement);
    //   }
    // });
  }
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
  ngOnInit() {
    this.initializeClassType();
    if (this.entries) {
      this.onSelectionChange(this.entries[0]);
    }

    this.initializelForm();
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]]
    });
  }
  public mountCard() {
    this.stripeService.elements().subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      if (true) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#666EE8",
              color: "#31325F",
              lineHeight: "40px",
              fontWeight: 300,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: "18px",
              "::placeholder": {
                color: "#CFD7E0"
              }
            }
          }
        });
        if (this.cardRef && this.cardRef.nativeElement) {
          this.card.mount(this.cardRef.nativeElement);
        }
      }
    });
  }


  private getTermsAndConditions() {
    this.groupClassService.getTermsAndConditions().subscribe(
      response => {
        this.pdfSrc = response["data"];
        this.pdfSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(response["data"]);
        console.log(this.pdfSrc)
        
      },
      (err: HttpErrorResponse) => {}
    );
  }
  //stripe = Stripe("pk_test_mk2BAoCYKCaDg5gWKwLReuId00OtuIaigj");
  public getClientToken(object) {
    console.log("enter into client token", object);
    //this.secretToken = null;
    this.final_amount = object.price;
    this.total_amount = object.price;
    this.mountCard();
    $("#terms-and-condition-box").modal("show");
        this.getTermsAndConditions();
    if (object.price == 0 || object.price == null) {
      this.enroll = true;
      this.discountText.course_id = object.id;
    } else {
      this.discountText.course_id = object.id;
      this.enroll = false;
    }
    // $("#payment-box").modal("show");

    //this.cardRef.reset();
    //this.stripeTest;
    // this.groupClassService
    //   .getClientToken(object.id, object.price)
    //   .subscribe(response => {
    //     //  headers: new HttpHeaders({
    //     //   'Authorization':this.authToken,
    //     //   // 'course_id': object.id,
    //     //   'Accept-Language':  'english'
    //     //  })
    //     // })

    //     //this.onGoingClassList = response["data"];
    //     this.secretToken = response["data"];
    //     console.log("secret token ", this.secretToken);
    //   });
  }
  public openPayment(e, data) {
    if ((data!=null) && data.price) {
      this.final_amount = data.price;
      this.total_amount = data.price;
    }
    console.log("DATA", data);

    e.stopPropagation();
    $("#terms-and-condition-box").modal("hide");
    const data1 = {
      id: this.discountText.course_id
    };
    if (data == null) {
      if (this.enroll==true) {
        this.enrollSelectedClass(data1);
      } else {
        // this.mountCard();
        // $("#payment-box").modal("show");
        this.enrollSelectedClass(data1);
        this.mountCard();
        // this.final_amount = '100';
        // this.temp = '200';
        $("#payment-box-show").modal("show");
      }
    } else {
      this.mountCard();
      $("#payment-box-show").modal("show");
    }
  }
  public getDiscount(obj) {
    console.log("obj", obj);
    this.discountText = {
      discount_code: "",
      course_id: obj.id
    };
    this.total_amount = obj.price;
    this.groupClassService.getDiscount(obj).subscribe(
      res => {
        this.discountList = res["data"];
        // if (res["data"].length != 0) {
        //   this.discountText.course_id = res["data"][0].course_id;
        // }
        console.log("discount codes", this.discountList);
        //this.discountText.discount_code = res["data"][0].discount_code;
        $("#discount-box").modal("show");
      },
      error => {
        console.log(error);
        this.toastr.error("", error);
      }
    );
  }
  public getDiscountedPrice(data) {
    console.log("discount coupon obj", data);
    this.discountText.discount_code = data.discount_code;
    this.groupClassService.getDiscountedPrice(data).subscribe(
      res => {
        console.log("res", res);
        this.toastr.success("Coupon code applied !");
        $("#discount-box").modal("hide");
        this.final_amount = res["data"][0].payable_amount;
        this.mountCard();
        $("#payment-box-show").modal("show");
      },
      error => {
        console.log(error);
        this.toastr.error("", error.error.message);
      }
    );
  }
  buy(e) {
    const name = this.stripeTest.get("name").value;
    console.log("data", this.card);
    this.stripeService.createToken(this.card, { name }).subscribe(token => {
      console.log("Token created correctly", token);

      const data = {
        token: token.token.id,
        amount: this.final_amount
      };
      this.groupClassService.postStripe(data).subscribe(
        res => {
          console.log("post payment", res);
          if (res["data"].status == "succeeded") {
            const data = {
              response: res["data"],
              discount_code: this.discountText.discount_code,
              payable_amount: this.final_amount,
              total_amount: this.total_amount,
              course_id: this.discountText.course_id
            };
            this.groupClassService.postStripeResponse(data).subscribe(
              res => {
                console.log("post stripe res", res);
                $("#payment-box-show").modal("hide");
                $("#payment-success-box").modal("show");
                // Swal("Payment Successful!", "", "success");
                this.getGroupUpcomingResponseList(this.tabType, this.groupId);
                this.getMyCourseList("ONGOING");
              },
              error => {
                console.log(error);
                this.toastr.error("", error.error.error.message);
              }
            );
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.toastr.error("", err.error.error.message);
        }
      );
    });
    // this.stripeService.confirmPaymentIntent(this.secretToken, {
    //   return_url: '',
    //   use_stripe_sdk: true,
    //       payment_method: {
    //         card: this.card
    //       }
    //     })
    //     .then(function(result) {
    //       if (result.error) {
    //         // Show error to your customer (e.g., insufficient funds)
    //         console.log(result.error.message);
    //       } else {
    //         // The payment has been processed!
    //         if (result.paymentIntent.status === "succeeded") {
    //         }
    //       }
    //     });
  }

  private initializeClassType() {
    this.entries = [
      {
        description: "Group Class",
        id: 1,
        name: "GROUP"
      },
      {
        description: "Private Class",
        id: 2,
        name: "PRIVATE"
      },
      {
        description: "Semi-Private",
        id: 3,
        name: "SEMI-PRIVATE"
      }
    ];
  }
  public onSelectionChange(entry) {
    // clone the object for immutability
    this.selectedEntry = Object.assign({}, this.selectedEntry, entry);
  }

  /*
  @method getSelectedCouseDetail
  @ click on new upcoming course will ask choose type of course
   */
  public getSelectedCouseDetail() {
    switch (this.selectedEntry["description"]) {
      case "Group Class":
        $("#myModal").modal("hide");
        this.getCourseLevelListResponse();
        $("#group-type").modal({
          backdrop: "static",
          keyboard: false
        });

        break;
      case "Private Class":
        // this.getNewUpcomingResponseList(this.selectedEntry['name']);
        $("#myModal").modal("hide");
        $("#upcomingCourses").modal("hide");
        // this.getCourseLevelListResponse();
        $("#private-message").modal({
          backdrop: "static",
          keyboard: false
        });
        break;
      case "Semi-Private":
        this.getNewUpcomingResponseList(this.selectedEntry["name"]);
        $("#myModal").modal("hide");
        //msg box
        $("#semi-private-message").modal({
          backdrop: "static",
          keyboard: false
        });

        break;
    }
  }

  private getCourseLevelListResponse() {
    this.groupClassService.getCourseLevelList().subscribe(
      response => {
        this.courseLevelList = response["data"];
      },
      (err: HttpErrorResponse) => {}
    );
  }

  public redirectToSelectedTab() {
    switch (this.selectedEntry["description"]) {
      case "Group Class":
        this.getGroupUpcomingResponseList(
          this.selectedEntry["name"],
          this.levelId
        );

        $("#group-type").modal("hide");
        break;
      case "Private Class":
        this.getNewUpcomingResponseList(this.selectedEntry["name"]);
        $("#group-type").modal("hide");
        break;
    }
  }
  private getRoutingParameers() {
    this.route.queryParams.subscribe(params => {
      if (params["id"]) {
        this.groupId = params["id"];
      }
      this.tabType = params["type"];
      // this.getSelectedTabResponse(this.tabType);
    });
  }

  public getSelectedTabResponse(type) {
    switch (type) {
      case "GROUP":
        this.getGroupUpcomingResponseList(type, this.groupId);
        $("#navTabSection li")
          .eq(0)
          .find(".nav-link")
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .removeClass("fade");
        break;
      case "MyCourse":
        this.getMyCourseList("ONGOING");
        $("#navTabSection li")
          .eq(1)
          .find(".nav-link")
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(1)
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(1)
          .removeClass("fade");
        break;
      case "PRIVATE":
        this.getNewUpcomingResponseList(type);
        $("#navTabSection li")
          .eq(0)
          .find(".nav-link")
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .removeClass("fade");
        break;
      case "SEMI-PRIVATE":
        this.getNewUpcomingResponseList(type);
        $("#navTabSection li")
          .eq(0)
          .find(".nav-link")
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .removeClass("fade");
        break;
      case "MySchedule":
        $("#navTabSection li")
          .eq(2)
          .find(".nav-link")
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(2)
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(2)
          .removeClass("fade");
        // this.getCalendarSlots();
        break;
    }
  }

  public getCalendarSlots(month, year) {
    this.groupClassService.getCalendarSlots(month, year).subscribe(
      response => {
        this.bindEventInCalendar(response["data"]);
        this.isEvent = true;

        setTimeout(() => {
          this.selectedDateToggleClass();
          this.calendarHeaderConfiguration();
          // this.getSelectedTabResponse(this.tabType);
        }, 0);
      },
      (err: HttpErrorResponse) => {}
    );
  }

  public bindEventInCalendar(response) {
    response.map(item => {
      if (item.course_slots.length > 0 || item.event_slots.length > 0) {
        let date = item.date.split("/");
        let eventDate = date[1] + "/" + date[0] + "/" + date[2];
        this.events.push({
          start: startOfDay(new Date(eventDate)),
          // title: 'An event with no end date',
          eventObject: item.event_slots,
          courseObject: item.course_slots,
          color: colors.yellow
          //  actions: this.actions
        });
      }
    });
  }
  ngOnChanges() {
    console.log(this.events);
    // changes.prop contains the old and the new value...
  }
  public getGroupUpcomingResponseList(type, id) {
    this.groupClassService
      .getGroupUpcomingClassList(type, id)
      .subscribe(response => {
        this.onGoingClassList = response["data"];
      });
  }

  public getNewUpcomingResponseList(type) {
    this.groupClassService.getNewUpcomingClassList(type).subscribe(response => {
      this.onGoingClassList = response["data"];
    });
  }

  public getMyCourseList(key) {
    if (this.completedFlag == false) {
      key = "ONGOING";
    } else {
      key = "COMPLETED";
    }
    this.groupClassService.getOngoingCourseList(key).subscribe(response => {
      this.courseList = response["data"];

    });
  }
  public closePayment(){
    $("#payment-success-box").modal("hide");
  }
  onPaymentStatus(response): void {
    this.responsePayment = response;
  }

  // getClientToken_old(object): Observable<string> {
  //   console.log('group class inside')
  //   console.log(localStorage.getItem("studentAuthKey"))
  //   this.authToken=localStorage.getItem("studentAuthKey")
  //   var course_id=28;
  //   return this.http
  //     // .get('api/braintree/getclienttoken', { responseType: 'json' })

  //     .get('http://test.iglulabs.com:4000/payment-service/api/v1/payment/client-token ', {

  //     .map((response: any) => {
  //       console.log('response');
  //       console.log(response)
  //       return response;
  //     })
  //     .catch((error) => {
  //       return Observable.throw(error);
  //     });

  // }

  public enrollSelectedClass(object) {
    let postParam = {
      course_id: object.id
    };

    // return this.http
    //   .get('http://test.iglulabs.com/payment-service/api/v1/payment/client-token', { responseType: 'json' })
    //   .map((response: any) => {
    //     console.log(response.token);
    //     return response.token;
    //   })
    //   .catch((error) => {
    //     return Observable.throw(error);
    //   });
    this.groupClassService.courseEnroll(postParam).subscribe(
      response => {
        this.getGroupUpcomingResponseList(this.tabType, this.groupId);
        // this.courseList=response['data']; already hided
        $("#navTabSection li")
          .eq(0)
          .find(".nav-link")
          .removeClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .removeClass("active");
        $(".tab-content .tab-pane")
          .eq(0)
          .removeClass("fade");

        this.getMyCourseList("ONGOING");
        $("#navTabSection li")
          .eq(1)
          .find(".nav-link")
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(1)
          .addClass("active");
        $(".tab-content .tab-pane")
          .eq(1)
          .removeClass("fade");
        this.toastr.success("Course Enrolled successfully", "Success", {
          positionClass: "toast-bottom-right"
        });
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message, "Error", {
          positionClass: "toast-bottom-right"
        });
      }
    );
  }
  private getSelectedTabContainerResponse(item) {
    this.selectedItem = item;
    switch (item.tabName) {
      case "Ongoing Course":
        this.completedFlag = false;
        this.getMyCourseList("ONGOING");
        break;
      case "Completed Course":
        this.completedFlag = true;
        this.getMyCourseList("COMPLETED");
        break;
      case "Upcoming Course":
        break;
    }
  }

  select() {
    this.selectedItem = this.myCourseTab[0];

    $("#myModal").modal({
      backdrop: "static",
      keyboard: false
    });
  }

  public selectTab(type) {
    // this.getMyCourseList('ONGOING');
    if (this.completedFlag == false) {
      this.getMyCourseList("ONGOING");
    } else {
      this.getMyCourseList("COMPLETED");
    }

    switch (type) {
      case "upcoming":
        this.selectedItem = this.myCourseTab[0];

        this.isOngoingTabSelected = false;
        this.isViewActivity = false;
        $("#myModal").modal({
          backdrop: "static",
          keyboard: false
        });

        if (this.tabType == "MyCourse" || this.tabType == "MySchedule") {
          this.isOngoingTabSelected = false;
          this.isViewActivity = false;
          //       // if(this.tabType!='GROUP') {
          $("#myModal").modal({
            backdrop: "static",
            keyboard: false
          });
        }
        //else if(this.tabType=='PRIVATE'){
        //       this.isOngoingTabSelected=false;
        //       this.getNewUpcomingResponseList('PRIVATE');
        // }else{
        //       this.isOngoingTabSelected=false;
        //       if(this.groupId){
        //         this.getGroupUpcomingResponseList(this.tabType,this.groupId);
        //       }else{
        //         this.getNewUpcomingResponseList(this.tabType);
        //       }
        //     }

        break;
      case "myCourse":
        if (this.completedFlag == false) {
          this.getMyCourseList("ONGOING");
        } else {
          this.getMyCourseList("COMPLETED");
        }
        // this.getMyCourseList('ONGOING');
        //$('#myCourse').addClass('active')

        break;
      case "mySchedule":
        this.isViewActivity = false;
        this.selectedItem = this.myCourseTab[0];
        this.isOngoingTabSelected = false;
        break;
    }
  }

  /**
   * @method getOngoingSection
   * @param item
   * @param click in mycouser section ongoing tab
   */
  public sessionClassListResponse;
  public getOngoingSection(item) {
    this.isOngoingTabSelected = true;
    this.selectedOngoingCourse = item;
    this.class_service_type = item.service_type;
    this.selectedOngoingItem = this.onGoingSectionTab[0];
    this.setGoogleAnalyticInfo(this.selectedOngoingCourse);

    this.groupClassService.getSessinClassList(item.id).subscribe(response => {
      this.scheduleSessionClassList = response["data"];
    });
  }
  setGoogleAnalyticInfo(object) {
    if (object) {
      let authToken = this.googleAnalyticsService.getAuthKey();
      if (authToken) {
        let decodedToken = this.googleAnalyticsService.decodeAuthToken(
          authToken
        );
        this.googleAnalyticsService.setAnalyticsResponse(
          decodedToken.email,
          "My Courses",
          object.name
        );
      }
    }
  }
  public getCompletedCourse(item) {
    this.isOngoingTabSelected = true;
    this.selectedOngoingCourse = item;
    this.class_service_type = item.service_type;
    this.selectedOngoingItem = this.onGoingSectionTab[1];
    this.groupClassService.getSessinClassList(item.id).subscribe(response => {
      this.scheduleSessionClassList = response["data"];
    });
  }
  public selectedOldCourseClass;
  public makeUpSelectedClass(item) {
    $("#reshedule-slots").modal({
      backdrop: "static",
      keyboard: false
    });
    this.oldSlotID = item.id;
    this.selectedOldCourseClass = item;
    this.getAvailableTimeSlotList(item.id);
  }

  public reSelectedSelectedClass(item) {
    $("#private-reschedule-type").modal({
      backdrop: "static",
      keyboard: false
    });
    this.currentSlotId = item.id;
  }
  public getAvailableTimeSlotList(id) {
    this.groupClassService.getAvailableTimeSlotList(id).subscribe(response => {
      this.availableSlotResponseLength = response["data"].length;
      this.availableTimeSlotList = response["data"];
    });
  }
  public selectSlotChange(slot) {
    this.slotName = slot.course_name;
    this.reschduleTime = slot.date_time_text;
  }
  public rescheduleClassSlot(id) {
    $("#reshedule-slots").modal("hide");
    $("#confirm-type").modal({
      backdrop: "static",
      keyboard: false
    });
    this.currentSlotId = id;
  }

  public selectedSessionObhect;
  public getActivityList(object) {
    if (object) {
      this.isViewActivity = true;
      this.selectedSessionObhect = object;
    }
  }
  public backToSession(number) {
    if (number == 1) {
      this.getMyCourseList("ONGOING");
      this.isViewActivity = false;
    } else {
      this.getMyCourseList("COMPLETED");
      this.isViewActivity = false;
    }
  }
  public confirmToRescheduleClass() {
    // if(this.class_service_type=='GROUP'){
    var postData = {
      old_slot_id: this.oldSlotID,
      new_slot_id: this.currentSlotId
    };
    this.groupClassService.postResheduleClass(postData).subscribe(
      response => {
        this.toastr.success(
          "Request for class reschedule is confirmed. If the class is full, you will receive an email in 48h from Spanish World offering you other options",
          "Success",
          { positionClass: "toast-bottom-right" }
        );
        $("#reshedule-slots").modal("hide");
        // this.getSessionClassList(this.selectedOngoingCourse);

        $("#confirm-type").modal("hide");

        this.selectedSlotId = "";
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message, "Error", {
          positionClass: "toast-bottom-right"
        });
      }
    );
  }

  /*
  @method reSchedulePrivateSemiClass
  @description for upcoming course click on reschedule course
   */
  public reSchedulePrivateSemiClass() {
    var postParam = {
      message: this.slotChangeMessage,
      type: this.class_service_type,
      slot_id: this.currentSlotId
    };
    //console.log("submit--", postParam);
    if (postParam.message != undefined) {
      this.groupClassService.requestRescheduling(postParam).subscribe(
        response => {
          this.toastr.success(
            "Request For class Reschedule has been successfully approved",
            "Success",
            { positionClass: "toast-bottom-right" }
          );
          $("#private-reschedule-type").modal("hide");
          this.getSessionClassList(this.selectedOngoingCourse);

          // $('#confirm-type').modal('hide');
          // this.getSessionClassList();
          this.selectedSlotId = "";
          this.slotChangeMessage = "";
        },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error.message, "Error", {
            positionClass: "toast-bottom-right"
          });
        }
      );
    } else {
      this.toastr.error("Please add message");
    }
  }
  //Create a function which receives the value counting of stars click,
  //and according to that value we do change the value of that star in list.
  public onRateChange(item) {
    this.selectedRatingSlotId = item.id;
    this.displayRatingScore = 0;

    $("#ratingModal").modal("show");
  }
  public viewSharedNotes(item) {
    this.groupClassService.getSharedNotes(item.id).subscribe(response => {
      this.sharedNotes = response["data"];
      this.sharedNotesCount = response["data"].length;

      $("#Shared-Notes").modal("show");
    });
  }
  public viewSharedModel() {
    $("#Shared-Notes").modal("show");
  }
  public submitGivenRating(score) {
    this.selectedRating = score;
  }
  public backToCourse() {
    this.isOngoingTabSelected = false;
  }

  public saveGivenRating() {
    let postParam = {
      slot_id: this.selectedRatingSlotId,
      rating: this.selectedRating ? this.selectedRating : 0,
      comment: this.ratingMessage ? this.ratingMessage : ""
    };
    this.saveSelectedRating(postParam);
  }

  public saveSelectedRating(postParam) {
    this.groupClassService.saveClassRating(postParam).subscribe(
      response => {
        this.toastr.success("Thanks for rating", "Success", {
          positionClass: "toast-bottom-right"
        });
        this.getSessionClassList(this.selectedOngoingCourse);
        this.selectedRating = "";
        this.selectedRatingSlotId = "";
        $("#ratingModal").modal("hide");
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message, "Error", {
          positionClass: "toast-bottom-right"
        });
      }
    );
  }
  public closeRatingModal() {
    this.selectedRating = 0;
    this.displayRatingScore = 0;
    this.selectedRatingSlotId = "";
    $("#ratingModal").modal("hide"); //rating-modal-type name of modal changed
  }

  public getSessionClassList(item) {
    this.groupClassService.getSessinClassList(item.id).subscribe(response => {
      this.scheduleSessionClassList = response["data"];
    });
  }

  private selectedSesionTab(item) {
    this.selectedOngoingItem = item;
  }

  /**
   * @method dayClicked
   * @param event
   * @description mehtod for my schedule -once date selected call and pass selected Date as param
   * @memberof GroupClassComponent
   */
  public selectedDateCourseList;
  public selectedDateEventList;

  public dayClicked(event, value) {
    this.selectedDate = event.date.toLocaleDateString();

    if (event.events.length > 0) {
      this.selectedDateCourseList = event.events[0].courseObject;
      this.selectedDateEventList = event.events[0].eventObject;
    } else {
      this.selectedDateCourseList = "";
      this.selectedDateEventList = "";
    }
  }

  public reScheduleSelectedCalenderSlot(item) {
    switch (item.service_type) {
      case "GROUP":
        $("#reshedule-slots").modal({
          backdrop: "static",
          keyboard: false
        });
        this.oldSlotID = item.slot_id;
        this.getAvailableTimeSlotList(item.slot_id);
        break;
      case "PRIVATE":
        this.class_service_type = item.service_type;
        $("#private-reschedule-type").modal({
          backdrop: "static",
          keyboard: false
        });
        this.currentSlotId = item.slot_id;
        break;
      case "SEMI-PRIVATE":
        this.class_service_type = item.service_type;

        $("#private-reschedule-type").modal({
          backdrop: "static",
          keyboard: false
        });
        this.currentSlotId = item.slot_id;
        break;
    }
  }
  ngAfterViewInit() {
    this.selectedDateToggleClass();
    this.calendarHeaderConfiguration();
    this.getSelectedTabResponse(this.tabType);

    // this.getCalendarSlots();
  }
  /**
   * @method previousMonth
   * @description mehtod for my schedule -once click on previous button, configure header name and date click toggle class
   * @memberof GroupClassComponent
   */
  public previousMonth(viewDate) {
    this.isEvent = false;
    console.log(viewDate);
    let month = viewDate.getMonth() + 1;
    let year = viewDate.getFullYear();
    $("mwl-calendar-month-cell").unwrap();
    this.getCalendarSlots(month, year);

    // $('.calendar-day-cell').unwrap();
    //
    // // $('cal-cell-row').unwrap();
    // setTimeout(() => {
    // this.selectedDateToggleClass();
    // this.calendarHeaderConfiguration();
    // }, 0)
  }
  /**
   * @method nextMonth
   * @description mehtod for my schedule -once click on next button, configure header name and date click toggle class
   * @memberof GroupClassComponent
   */
  public nextMonth(viewDate) {
    console.log(viewDate);
    this.isEvent = false;
    let month = viewDate.getMonth() + 1;
    let year = viewDate.getFullYear();
    $("mwl-calendar-month-cell").unwrap();
    this.getCalendarSlots(month, year);

    // $('.calendar-day-cell').unwrap();
    //
    // setTimeout(() => {
    // this.selectedDateToggleClass();
    // this.calendarHeaderConfiguration();
    // }, 0)
  }
  /**
   * @method toDayDateClicked
   * @description mehtod for my schedule- once click on todate button, configure header name and date click toggle class
   * @memberof GroupClassComponent
   */
  public toDayDateClicked() {
    $("mwl-calendar-month-cell").unwrap();
    setTimeout(() => {
      this.selectedDateToggleClass();
      this.calendarHeaderConfiguration();
    }, 0);
  }

  /**
   * @method selectedDateToggleClass
   * @description mehtod for my schedule-toggle selected date class once click on calndar date
   * @memberof GroupClassComponent
   */
  private selectedDateToggleClass() {
    $(".cal-in-month").click(function(e) {
      $(".cal-in-month .cal-cell-top")
        .children(".cal-day-number.selectedDay")
        .removeClass("selectedDay"); // remove class for selected day
      $(".cal-in-month")
        .children()
        .next()
        .children(".cal-event.selectedEvent")
        .removeClass("selectedEvent"); // remove class for selected event
      $(this)
        .children()
        .next()
        .children(".cal-event")
        .addClass("selectedEvent"); // add class for selected event
      $(this)
        .children(".cal-cell-top")
        .children(".cal-day-number")
        .addClass("selectedDay"); // add class for selected day
    });
  }

  /**
   * @method calendarHeaderConfiguration
   * @description mehtod for my schedule- show only 3 letter in day name and add div element to each calender day cell
   * @memberof GroupClassComponent
   */
  private calendarHeaderConfiguration() {
    $("mwl-calendar-month-cell").each(function() {
      $(this).wrap("<div class='calendar-day-cell'></div>");
    });
    $(".cal-cell-row.cal-header .cal-cell").each(function() {
      var calenderHeaderText = $(this)
        .text()
        .trim();
      $(this).html(calenderHeaderText.substring(0, 3));
    });
  }

  hideConetn() {
    $("#private-message").modal("hide");
  }

  hideConetnSemiPrivate() {
    $("#semi-private-message").modal("hide");
  }
  initializelForm() {
    this.MessagecreateForm = this.fb.group({
      message: ["", Validators.required]
    });
  }

  messageSend(val) {
    let Data = {
      message: val.message,
      type: "PRIVATE"
    };

    this.groupClassService.message(Data).subscribe(
      response => {
        this.toastr.success("Message has been send successfully ", "Success", {
          positionClass: "toast-bottom-right"
        });

        $("#private-message").modal("show");
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message, "Error", {
          positionClass: "toast-bottom-right"
        });
      }
    );
  }
  messageSendSemiPrivate(val) {
    let Data = {
      message: val.message,
      type: "SEMI-PRIVATE"
    };

    this.groupClassService.message(Data).subscribe(
      response => {
        this.toastr.success("Message has been send successfully ", "Success", {
          positionClass: "toast-bottom-right"
        });

        $("#semi-private-message").modal("show");
      },
      (err: HttpErrorResponse) => {
        this.toastr.error(err.error.message, "Error", {
          positionClass: "toast-bottom-right"
        });
      }
    );
  }
}
