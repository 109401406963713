import { Routes, RouterModule } from "@angular/router";
import { ModuleWithProviders } from "@angular/core";

import { LasLilasComponent } from "./laslilas.component";
import { LandingComponent } from "./landing/landing.component";

export const routes: Routes = [
  // {
  //   path: "",
  //   component: LasLilasComponent,
  //   children: [
  //     { path: "", redirectTo: "landing", pathMatch: "full" },
  //     {
  //       path: "landing",
  //       component: LandingComponent,
  //       data: { breadcrumb: "Dashboard" }
  //     },
  //     {
  //       path: "home",
  //       loadChildren: () =>
  //         import("./dashboard/dashboard.module").then(m => m.DashboardModule),
  //       data: { breadcrumb: "Dashboard" }
  //     },
  //     {
  //       path: "class",
  //       loadChildren: () =>
  //         import("./group-class/group-class.module").then(
  //           m => m.GroupClassModule
  //         ),
  //       data: { breadcrumb: "Group-Class" }
  //     },
  //     {
  //       path: "individualClass",
  //       loadChildren: () =>
  //         import("./individual-class/individual-class.module").then(
  //           m => m.IndividualClassModule
  //         ),
  //       data: { breadcrumb: "individual-Class" }
  //     }
  //   ]
  // }
  {
    path: "",
    component: LasLilasComponent,
    children: [
      { path: "", redirectTo: "landing", pathMatch: "full" },
      {
        path: "landing",
        component: LandingComponent,
        data: { breadcrumb: "Dashboard" }
      },
      {
        path: "home",
        loadChildren: "app/modules/dashboard/dashboard.module#DashboardModule",
        data: { breadcrumb: "Dashboard" }
      },
      {
        path: "class",
        loadChildren:
          "app/modules/group-class/group-class.module#GroupClassModule",
        data: { breadcrumb: "Group-Class" }
      },
      {
        path: "individualClass",
        loadChildren:
          "app/modules/individual-class/individual-class.module#IndividualClassModule",
        data: { breadcrumb: "individual-Class" }
      }
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
