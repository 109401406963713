import {Option} from '../dashboard/survey.modal';

export class CourseActivity {
  id: number;
  level_id: any;
  cat_id: any;
  embed_link:any;
  level_name:any;

  constructor(data: any) {
    data = data || {};
    this.id = data.id;
    this.level_id=data.level_id;
    this.cat_id = data.cat_id;
    this.embed_link=data.embed_link;
    this.level_name=data.level_name;
  }
}
export class MyActivityModel {
  course_name:any;
  completed_activities_percentage:any;
  remaining_activities:any;
  arr_course_activities:CourseActivity[];
  id: any;
  level_id:any;
  cat_id: any;
  embed_link:any;
  constructor(data: any) {
    if (data) {
      this.completed_activities_percentage=data.completed_activities_percentage
      this.remaining_activities=data.remaining_activities;
      this.arr_course_activities=[];
      data.arr_course_activities.forEach(q => {
        this.arr_course_activities.push(new CourseActivity(q));
      });
    }
  }
}
