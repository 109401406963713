import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { routing } from "./laslilas.routing";

import { LasLilasComponent } from "./laslilas.component";
import { HeaderComponent } from "../core/header/header.component";
import { FooterComponent } from "../core/footer/footer.component";
import { CapitalizeFirstPipe } from "../core/first-name.filter";
import { MyDatePickerModule } from "mydatepicker";
import { ProfileModule } from "../core/header/profile.module";
import { NotificationComponent } from "../core/header/notification/notification.component";
import { ViewProfileComponent } from "../core/header/view-profile/view-profile.component";
import { StarRatingDirective } from "../core/common-directive/star-rating.directive";
import { CommonDirectiveModule } from "../core/common-directive/common.module";
import { LandingComponent } from './landing/landing.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    MyDatePickerModule,
    CommonDirectiveModule
  ],
  declarations: [
    HeaderComponent,
    LasLilasComponent,
    FooterComponent,
    CapitalizeFirstPipe,
    NotificationComponent,
    ViewProfileComponent,
    LandingComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NotificationComponent,
    ViewProfileComponent
  ]
})
export class LasLilasModule {}
