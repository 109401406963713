
export class Option {
  id: number;
  text: string;
  status: string;
  selected: boolean;
  answerText: string;
  constructor(data: any) {
    data = data || {};
    this.id = data.id;
    this.text = data.text;
    this.status = data.status;
  }
}


export class Question {
  id: number;
  text: string;
  status: string;
  type:string;
  answerText:string;
  answer_options: Option[];

  constructor(data: any) {
    data = data || {};
    this.id = data.id;
    this.text=data.text;
    this.type = data.type;
    this.status=data.status;
    this.answer_options = [];
    data.answer_options.forEach(o => {
      this.answer_options.push(new Option(o));
    });
  }
}
export class Survey {
  survey_id: number;

  questions: Question[];

  constructor(data: any) {
    if (data) {
      this.survey_id = data.survey_id;
      this.questions = [];
      data.questions.forEach(q => {
        this.questions.push(new Question(q));
      });
    }
  }
}
