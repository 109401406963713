import { Injectable } from "@angular/core";
import {
  CanLoad,
  CanActivate,
  Route,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

@Injectable()
export class AuthGuardService implements CanLoad, CanActivate {
  constructor(private router: Router) {}
  canLoad(route: Route): boolean {
    let url: string = route.path;
    console.log("Url:" + url);
    if (localStorage.getItem("studentAuthKey")) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let url: string = state.url;
    console.log("Url:" + url);
    if (localStorage.getItem("studentAuthKey")) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }
}
