import {
  Component,
  Pipe,
  PipeTransform,
  ViewEncapsulation
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Survey } from "../dashboard/survey.modal";
import { GroupClassService } from "../group-class/group-class.service";
declare var jQuery: any;

@Component({
  selector: "app-my-survey",
  templateUrl: "./my-survey.component.html",
  // styleUrls: ['./login.component.scss'],
  providers: [GroupClassService],
  encapsulation: ViewEncapsulation.Emulated
})
export class MySurveyComponent {
  public surveyResponse = [];
  survey: Survey = new Survey(null);
  surveys: any[];
  mode = "quiz";
  pager = {
    index: 0,
    size: 1,
    count: 1
  };
  constructor(
    public toastr: ToastrService,
    public dashboardService: GroupClassService,
    private _title: Title
  ) {
    this.getSurveyList();
  }
  getSurveyList() {
    this.dashboardService.survey().subscribe(response => {
      if (response["data"].length > 0) {
        this.survey = new Survey(response["data"]["0"]);
        this.pager.count = this.survey.questions.length;
        jQuery("#survey-modal").modal({
          backdrop: "static",
          keyboard: false
        });
      }
    });
  }
  get getSurveyResponse() {
    return this.survey.questions
      ? this.survey.questions.slice(
          this.pager.index,
          this.pager.index + this.pager.size
        )
      : [];
  }

  public closeSurveyModal() {
    jQuery("#survey-modal").modal("hide");
  }
  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.mode = "quiz";
    }
  }
  submitSurvey() {
    let answers = [];
    let formatSurveyKeys = this.returnSurveyKeys(this.survey);
    if (this.validSurveyAnswer(formatSurveyKeys)) {
      this.dashboardService.answerSurvey(formatSurveyKeys).subscribe(
        response => {
          jQuery("#survey-modal").modal("hide");
          this.toastr.success(response.message, "Success", {
            positionClass: "toast-bottom-right"
          });
        },
        (err: HttpErrorResponse) => {
          this.toastr.error(err.error.message, "Error", {
            positionClass: "toast-bottom-right"
          });
        }
      );
    } else {
      this.toastr.error("Answer All Questions", "Error", {
        positionClass: "toast-bottom-right"
      });
    }
  }

  public validSurveyAnswer(response) {
    let count = 0;
    response.answers.map((item, index) => {
      if (item.answer_id) {
        count++;
      }
    });

    if (count == response.answers.length) {
      return true;
    } else {
      return false;
    }
  }
  returnSurveyKeys(survey) {
    let postParam = {
      answers: []
    };
    postParam["survey_id"] = survey.survey_id;
    survey.questions.map((answer, index) => {
      if (answer.type == "MULTI") {
        answer.answer_options.map((item, index) => {
          if (item.selectedKey) {
            postParam.answers.push({
              question_id: answer.id,
              answer_id: item.id,
              question_type: answer.type
            });
          }
        });
      } else {
        if (answer.answerText) {
          postParam["answers"].push({
            question_id: answer.id,
            answer_id: answer.answerText,
            question_type: answer.type
          });
        } else {
          postParam["answers"].push({
            question_id: answer.id,
            answer_id: "",
            question_type: answer.type
          });
        }
      }
    });
    return postParam;
  }

  public changeSurveyAnswer(parent, child, childId) {
    if (this.survey.questions[child].answer_options) {
      this.survey.questions[child].answer_options.map((item, index) => {
        if (item.id == childId) {
          item["selectedKey"] = true;
        } else {
          item["selectedKey"] = false;
        }
      });
    }
  }
}
