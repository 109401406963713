import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { AuthGuardService } from "./core/common-service/auth-guard.service";

// import { NotFoundComponent } from './core/not-found/not-found.component';

export const routes: Routes = [
  // { path: "", redirectTo: "", pathMatch: "full" },
  // //{ path: "landing", component: LandingComponent },
  // {
  //   path: "",
  //   loadChildren: () =>
  //     import("./modules/laslilas.module").then(m => m.LasLilasModule)
  // },
  // {
  //   path: "login",
  //   loadChildren: () =>
  //     import("./modules/login/login.module").then(m => m.LoginModule)
  // },
  // {
  //   path: "register",
  //   loadChildren: () =>
  //     import("./modules/register/register.module").then(m => m.RegisterModule)
  // },
  // // Yash 10/08/2018
  // {
  //   path: "forgotpass",
  //   loadChildren: () =>
  //     import("./modules/forgot-pass/forgotpass.module").then(
  //       m => m.ForgotpassModule
  //     )
  // },
  // {
  //   path: "resetpass/:email",
  //   loadChildren: () =>
  //     import("./modules/reset-pass/resetpass.module").then(
  //       m => m.ResetpassModule
  //     )
  // },
  // {
  //   path: "verifyotp/:email",
  //   loadChildren: () =>
  //     import("./modules/verify-otp/verifyotp.module").then(
  //       m => m.VerifyotpModule
  //     )
  // }
  { path: "", redirectTo: "", pathMatch: "full" },
  //{ path: "landing", component: LandingComponent },
  { path: "", loadChildren: "app/modules/laslilas.module#LasLilasModule" },
  { path: "login", loadChildren: "app/modules/login/login.module#LoginModule" },
  {
    path: "register",
    loadChildren: "app/modules/register/register.module#RegisterModule"
  },
  // Yash 10/08/2018
  {
    path: "forgotpass",
    loadChildren: "app/modules/forgot-pass/forgotpass.module#ForgotpassModule"
  },
  {
    path: "resetpass/:email",
    loadChildren: "app/modules/reset-pass/resetpass.module#ResetpassModule"
  },
  {
    path: "verifyotp/:email",
    loadChildren: "app/modules/verify-otp/verifyotp.module#VerifyotpModule"
  }
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule {}
export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  useHash: true
});
