import { Injectable, Injector } from "@angular/core";
import "rxjs/Rx";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
import { Observable } from "rxjs";
// import 'rxjs/add/operator/map';
import {
  Http,
  Headers,
  RequestOptions,
  Response,
  URLSearchParams
} from "@angular/http";
// import 'rxjs/add/operator/catch';

// const httpOptions = {
//   headers: new HttpHeaders({
//     course_id: "28",
//     Authorization:
//       "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijg3IiwiY3JlYXRlZF9vbiI6IjAwMDAtMDAtMDAgMDA6MDA6MDAiLCJjcmVhdGVkX2J5IjoiMCIsInVwZGF0ZWRfb24iOiIwMDAwLTAwLTAwIDAwOjAwOjAwIiwidXBkYXRlZF9ieSI6Ijg3IiwibmFtZSI6IlByaXlhIiwiZW1haWwiOiJwcml5YW5rYS5taXNocmFAaWdsdWxhYnMuY29tIiwicGFzc3dvcmQiOiIzNDNiMWM0YTNlYTcyMWIyZDY0MGZjODcwMGRiMGYzNiIsIm1vYmlsZSI6Ijk2MjAzNDgwMzUiLCJvdHAiOm51bGwsIm90cF9leHBpcmF0aW9uX3RpbWUiOm51bGwsInVzZXJfZ3JvdXAiOiIzIiwiaXNfdmVyaWZpZWQiOiIxIiwiY291bnRyeV9jb2RlIjoiSU4iLCJ6b25lX2lkIjoiMTk0IiwiZm9yZ290dGVuX3Bhc3N3b3JkX2NvZGUiOm51bGwsImZvcmdvdHRlbl9wYXNzd29yZF90aW1lIjpudWxsLCJkb2IiOiIxOTkyLTAxLTE4IiwiZ2VuZGVyIjoiRkVNQUxFIiwib2NjdXBhdGlvbiI6IlRlc3RlciIsIm5hdGlvbmFsaXR5IjoiODYiLCJhZGRyZXNzIjpudWxsLCJzY2hvb2xfbG9jYXRpb24iOiJMYXNsaWxhcy1TaW5nYXBvcmUiLCJwb3N0YWxfY29kZSI6bnVsbCwiY2l0eSI6bnVsbCwibGF0aXR1ZGUiOm51bGwsImxvbmdpdHVkZSI6bnVsbCwicHJvZmlsZV9pbWFnZV91cmwiOiJodHRwczpcL1wvczMuYW1hem9uYXdzLmNvbVwvbGFzLWxpbGFzLXRlc3RcL29yaWdpbmFsXC9pbWFnZXNcL2c4WXZXNG5EdUgxZFNYbS5qcGciLCJzdGF0dXMiOiIxIiwicmVnaXN0cmF0aW9uX2lkIjoiIiwicmVtYXJrcyI6bnVsbH0.2hrwWXL23KC3QmRbaKWQNH0YEVtj9uIcgpx12x5sWMM"
//   })
// };

@Injectable()
export class GroupClassService {
  public _headers = new HttpHeaders();
  public baseURL = environment.config.apiKey;
  // public baseURLPayment = environment.config.apiKeyPayment;
  constructor(public http: HttpClient) {}
  authToken;
  getOngoingCourseList(data: any): Observable<any> {
    return this.http.get(this.baseURL + "/student/course/my_course/" + data);
  }

  getCourseLevelList(): Observable<any> {
    return this.http.get(
      this.baseURL + "/student/metadata/course_level_active"
    );
  }
  courseEnroll(id) {
    return this.http.post(this.baseURL + "/student/course/enroll", id);
    // return this.http.get('http://test.iglulabs.com:4000/payment-service/api/v1/payment/client-token',id);
  }

  getTermsAndConditions(): Observable<any> {
    return this.http.get(
      this.baseURL + `/student/course/termsAndConditions`
    );
  }

  getClientToken(id, price): Observable<any> {
    return this.http.get(
      this.baseURL + `/student/course/stripe_client_secret?course_id=${id}&amount=${price}.00`
    );
    // this.authToken=localStorage.getItem("studentAuthKey");
    // let headers = new HttpHeaders().set('', '');
    //   headers = headers.set('', '28');
    //   headers = headers.set('','');

    // return this.http.get('http://test.iglulabs.com:4000/payment-service/api/v1/payment/client-token',{
    //   headers: new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijg3IiwiY3JlYXRlZF9vbiI6IjAwMDAtMDAtMDAgMDA6MDA6MDAiLCJjcmVhdGVkX2J5IjoiMCIsInVwZGF0ZWRfb24iOiIwMDAwLTAwLTAwIDAwOjAwOjAwIiwidXBkYXRlZF9ieSI6Ijg3IiwibmFtZSI6IlByaXlhIiwiZW1haWwiOiJwcml5YW5rYS5taXNocmFAaWdsdWxhYnMuY29tIiwicGFzc3dvcmQiOiIzNDNiMWM0YTNlYTcyMWIyZDY0MGZjODcwMGRiMGYzNiIsIm1vYmlsZSI6Ijk2MjAzNDgwMzUiLCJvdHAiOm51bGwsIm90cF9leHBpcmF0aW9uX3RpbWUiOm51bGwsInVzZXJfZ3JvdXAiOiIzIiwiaXNfdmVyaWZpZWQiOiIxIiwiY291bnRyeV9jb2RlIjoiSU4iLCJ6b25lX2lkIjoiMTk0IiwiZm9yZ290dGVuX3Bhc3N3b3JkX2NvZGUiOm51bGwsImZvcmdvdHRlbl9wYXNzd29yZF90aW1lIjpudWxsLCJkb2IiOiIxOTkyLTAxLTE4IiwiZ2VuZGVyIjoiRkVNQUxFIiwib2NjdXBhdGlvbiI6IlRlc3RlciIsIm5hdGlvbmFsaXR5IjoiODYiLCJhZGRyZXNzIjpudWxsLCJzY2hvb2xfbG9jYXRpb24iOiJMYXNsaWxhcy1TaW5nYXBvcmUiLCJwb3N0YWxfY29kZSI6bnVsbCwiY2l0eSI6bnVsbCwibGF0aXR1ZGUiOm51bGwsImxvbmdpdHVkZSI6bnVsbCwicHJvZmlsZV9pbWFnZV91cmwiOiJodHRwczpcL1wvczMuYW1hem9uYXdzLmNvbVwvbGFzLWxpbGFzLXRlc3RcL29yaWdpbmFsXC9pbWFnZXNcL2c4WXZXNG5EdUgxZFNYbS5qcGciLCJzdGF0dXMiOiIxIiwicmVnaXN0cmF0aW9uX2lkIjoiIiwicmVtYXJrcyI6bnVsbH0.2hrwWXL23KC3QmRbaKWQNH0YEVtj9uIcgpx12x5sWMM',
    //   })
    // });

    //   let myHeaders = new Headers();
    //   myHeaders.append('Content-Type', 'application/json');
    //   let myParams = new URLSearchParams();
    //   myParams.append('course_id', id);
    //   let options = new RequestOptions({ headers: myHeaders, params: myParams });
    //   return this.http.get(this.baseURLPayment, options)
    // .map(this.extractData)
    // .catch(this.handleError);
  }
  getDiscount(data): Observable<any> {
    return this.http.get(
      this.baseURL + `/student/course/dicountCourseWise/${data.id}`
    );
  }
  getDiscountedPrice(data): Observable<any> {
    return this.http.get(
      this.baseURL +
        `/student/course/dicountPrice?discount_code=${data.discount_code}&course_id=${data.course_id}`
    );
  }
  postStripe(data): Observable<any> {
    return this.http.post(this.baseURL + `/student/course/stripePost`, data);
  }
  postStripeResponse(data): Observable<any> {
    return this.http.post(
      this.baseURL + `/student/course/stripe_response_web`,
      data
    );
  }
  getGroupUpcomingClassList(key, id): Observable<any> {
    return this.http.get(
      this.baseURL + "/student/course/upcoming_course/" + key + "/" + id
    );
  }
  getNewUpcomingClassList(key): Observable<any> {
    return this.http.get(
      this.baseURL + "/student/course/upcoming_course/" + key
    );
  }
  getSessinClassList(id): Observable<any> {
    return this.http.get(this.baseURL + "/student/course/session/" + id);
  }
  getAvailableTimeSlotList(id): Observable<any> {
    return this.http.get(
      this.baseURL + "/student/course/reschedule_session/" + id
    );
  }
  postResheduleClass(data): Observable<any> {
    return this.http.post(
      this.baseURL + "/student/course/reschedule_session",
      data
    );
  }
  requestRescheduling(data): Observable<any> {
    return this.http.post(
      this.baseURL + "/student/course/request_rescheduling",
      data
    );
  }
  saveClassRating(data): Observable<any> {
    return this.http.post(
      this.baseURL + "/student/course/store_class_rating",
      data
    );
  }
  getCalendarSlots(month, year): Observable<any> {
    return this.http.get(
      this.baseURL +
        "/student/course/calender_slots?month=" +
        month +
        "&year=" +
        year
    );
  }
  survey(): Observable<any> {
    return this.http.get(this.baseURL + "/student/course/survey");
  }
  answerSurvey(postParam): Observable<any> {
    return this.http.post(this.baseURL + "/student/course/survey", postParam);
  }
  message(data): Observable<any> {
    return this.http.post(
      this.baseURL + "/student/course/request_lesson",
      data
    );
  }
  getSharedNotes(id): Observable<any> {
    return this.http.get(this.baseURL + "/student/course/sharedNotes/" + id);
  }
}
