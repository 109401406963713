import {NgModule, Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

import {
    CalendarEvent,
    WeekDay,
    MonthView,
    MonthViewDay,
  } from 'calendar-utils';
// Start Rating Component
@Component({
	selector: 'calender',
    templateUrl: `./calender.directive.html`,
    styleUrls :[`./calender.directive.scss`]
})
export class CalenderDirective implements OnInit {

  @Input() viewDate;
	@Input() events;
	@Input() forDisplay = false;
    @Output() rateChanged = new EventEmitter();
    
     /**
   * @hidden
   */
  view: MonthView;
  public dateObj;
	constructor() {
   
   }

	ngOnInit() {
    this.dateObj= this.getDateParts(this.viewDate);
    this.setActiveType('MONTH', this.getDateParts(this.viewDate));
    
  }

 //to get all the date parts
 public getDateParts (date) {
  const month = (new Date(date)).getMonth(),
      day = (new Date(date)).getDate(),
      year = (new Date(date)).getFullYear(),
      fullDate = date,
      first = (new Date(date)).getDate() - (new Date(date)).getDay(),
      last = first + 6,
      dayFromDate = day,
      dayToDate = day,
      totaolDays= new Date(year, month+1, 0).getDate(),
      weekFromDate = new Date((new Date(date)).setDate(first)).getTime(),
      weekToDate = new Date((new Date(date)).setDate(last)).getTime();
      const monthFromDate = new Date((new Date(date)).getFullYear(), (new Date(date)).getMonth(), 1).getTime();
      const monthToDate = new Date((new Date(date)).getFullYear(), (new Date(date)).getMonth() + 1, 0).getTime();
      const yearFromDate = new Date((new Date(date)).getFullYear(), 0, 1).getTime();
      const yearToDate = new Date((new Date(date)).getFullYear(), 11, 31).getTime();
     
      return {
        month: month,
        day: day,
        year: year,
        fullDate: fullDate,
        first: first,
        totaolDays:totaolDays,
        last: last,
        dayFromDate: dayFromDate,
        dayToDate: dayToDate,
        weekFromDate: weekFromDate,
        weekToDate: weekToDate,
        monthFromDate: monthFromDate,
        monthToDate: monthToDate,
        yearFromDate: yearFromDate,
        yearToDate: yearToDate
   }
}

displayValue;
changeValue(type='MONTH', dateObj, flag) {
  
  var date = new Date(dateObj.year, dateObj.month, dateObj.day)
  switch (type) {
      case 'DAY':
          flag == "prev" ? Object.assign(dateObj, this.getDateParts(date.setDate(date.getDate() - 1))) : Object.assign(dateObj, this.getDateParts(date.setDate(date.getDate() + 1)));
          break;
      case 'MONTH':
          flag == "prev" ? Object.assign(dateObj, this.getDateParts(date.setMonth(date.getMonth() - 1))) : Object.assign(dateObj, this.getDateParts(date.setMonth(date.getMonth() + 1)));
          break;
      case 'WEEK':
          flag == "prev" ? Object.assign(dateObj, this.getDateParts(date.setDate(date.getDate() - 7))) : Object.assign(dateObj, this.getDateParts(date.setDate(date.getDate() + 7)));
          break;
      case 'YEAR':
          flag == "prev" ? Object.assign(dateObj, this.getDateParts(date.setFullYear(date.getFullYear() - 1))) : Object.assign(dateObj, this.getDateParts(date.setFullYear(date.getFullYear() + 1)));
          break;
  }
  this.displayValue = this.getValue(type, dateObj);
}
//to get the value based on the selected type(active tab)
public monthValue;
setActiveType (type, dateObj) {
  this.monthValue=dateObj;
  this.displayValue = this.getValue(type, dateObj);
};


getValue (type, dateObj) {
  var monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
  ];
  const active = type;
  if (type == 'DAY') {
      //return filter('date')(dateObj.fullDate, 'fullDate');
  } else if (type == 'MONTH') {
      return monthNames[dateObj.month] + ", " + dateObj.year;
  } else if (type == 'WEEK') {
     // return $filter('date')(dateObj.weekFromDate, 'longDate') + " - " + $filter('date')(dateObj.weekToDate, 'longDate');
  } else if (type == 'YEAR') {
      return dateObj.year;
  }
}

 
}
