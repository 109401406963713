import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';
import {LasLilasModule} from '../../modules/laslilas.module';
import {CapitalizeFirstPipe} from '../first-name.filter';
import { StarRatingDirective } from '../common-directive/star-rating.directive';


export const routes = [
  { path: '', component: StarRatingDirective, pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations:[
      StarRatingDirective
  ],
  exports:[
      StarRatingDirective
  ]
})

export class CommonDirectiveModule { }
