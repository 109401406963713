//import { Injectable } from '@angular/core';
import { Injectable, Injector } from "@angular/core";
import "rxjs/Rx";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class LandingService {
  public _headers = new HttpHeaders();
  public baseURL = environment.config.apiKey;
  constructor(private http: HttpClient) {}

  getSchools(): Observable<any> {
    // const headers = {
    //   headers: new HttpHeaders().set(
    //     "Content-Type",
    //     "application/x-www-form-urlencoded;charset=UTF-8"
    //   )
    // };
    let headers = new HttpHeaders().set("Content-Type", "QQQQQ");
    return this.http.get(this.baseURL + "/metadata/schools"
    );
  }
}
