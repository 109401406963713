import { ToastrService } from "ngx-toastr";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild
} from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ProfileService } from "../profile.service";
import * as Stripe from "ngx-stripe";
import Swal from "sweetalert2";
import { Elements, Element as StripeElement, StripeService } from "ngx-stripe";
import { GroupClassService } from "../../../modules/group-class/group-class.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
declare var $: any;

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],

  providers: [ProfileService],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  elements: Elements;
  card: StripeElement;
  @ViewChild("card") cardRef: ElementRef;
  displayRatingScore = 0;

  public total_amount = "";
  public stripeTest: FormGroup;
  course_id: any;

  constructor(
    private profileService: ProfileService,
    private stripeService: StripeService,
    private groupClassService: GroupClassService,
    private toastr: ToastrService,
    public fb: FormBuilder
  ) {
    this.mountCard();
  }
  ngOnInit() {
    this.getNotification();
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]]
    });
  }
  public notificationList;
  public paySelectedServiec() {
    $("#survey-modal").modal("show");
    // $('.user-notification').toggle();
  }
  public getNotification() {
    this.profileService.getNotification().subscribe(
      response => {
        this.notificationList = response.data.data;
      },
      (err: HttpErrorResponse) => {
        console.log("login err response: ", err);
      }
    );
  }

  ratingOpen(id) {
    // event.stopPropogation();
    localStorage.setItem("notification_id", id);
    $("#rating-modal-type").modal("show");
  }

  public showPaid(notification) {
    if (
      notification.opcode == "312" &&
      notification.service_type == "PRIVATE" &&
      notification.action_completed == "1"
    ) {
      return true;
    } else {
      return false;
    }
  }
  public showPay(notification) {
    if (
      notification.opcode == "312" &&
      notification.service_type == "PRIVATE" &&
      notification.action_completed == "0"
    ) {
      return true;
    } else {
      return false;
    }
  }
  public mountCard() {
    this.stripeService.elements().subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      if (true) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#666EE8",
              color: "#31325F",
              lineHeight: "40px",
              fontWeight: 300,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: "18px",
              "::placeholder": {
                color: "#CFD7E0"
              }
            }
          }
        });
        this.card.mount(this.cardRef.nativeElement);
      }
    });
  }
  public openPayment(e, data) {
    if (data && data.course_amount) {
      this.total_amount = data.course_amount;
      this.course_id = data.course_id;
    }
    console.log("DATA", data);
    e.stopPropagation();
    e.stopPropagation();
    $("#payment-box").modal("show");
  }
  public buy() {
    const name = this.stripeTest.get("name").value;
    console.log("data", this.card);
    this.stripeService.createToken(this.card, { name }).subscribe(token => {
      console.log("Token created correctly", token);

      const data = {
        token: token.token.id,
        amount: this.total_amount
      };
      this.groupClassService.postStripe(data).subscribe(
        res => {
          console.log("post payment", res);
          if (res["data"].status == "succeeded") {
            const data = {
              response: res["data"],
              discount_code: "",
              payable_amount: this.total_amount,
              total_amount: this.total_amount,
              course_id: this.course_id
            };
            this.groupClassService.postStripeResponse(data).subscribe(
              res => {
                console.log("post stripe res", res);
                $("#payment-box").modal("hide");
                Swal("Payment Successful!", "", "success");
              },
              error => {
                console.log(error);
                this.toastr.error("", error.error.message);
              }
            );
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.toastr.error("", err.error.error.message);
        }
      );
    });
  }
}
