export class Profile {
  name: string;
  mobile: number;
  "country_code": string;
  "dob": string;
  "gender": string;
  "occupation": string;
  "nationality": string;
  "school_location": string;
  "profile_image_url":any;
  "zone_id":string
}
