// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  googleAnalytics: {
    domain: "auto",
    trackingId: "UA-130238725-1"
  },
  config: {
    // h5plogin:'http://test.iglulabs.com/wordpress_h5p/index.php/wp-json/auth/v1/',
    h5plogin: "https://h5p.spanishworldgroup.com/index.php/wp-json/auth/v1/",
    // h5plogin: "http://10.1.1.89/index.php/wp-json/auth/v1/",
    apiKey: "https://api.spanishworldgroup.com/api/v1",
    // apiKey: "http://test.iglulabs.com/laslilas_backend_apis/www/api/v1",
    authDomain: "your authDomain goes here",
    databaseURL: "your databaseUrl goes here",
    projectId: "your projectId goes here", 
    storageBucket: "your storageBucket goes here",
    messagingSenderId: "your messagingSenderId goes here"
  }
};
