import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// component
import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(public ngxanalyticsGoogleAnalytics: NgxAnalyticsGoogleAnalytics) {
   //  this._translate.setDefaultLang('en');
    // this._translate.use('en');
    // const browserLang = _translate.getBrowserLang();
    // _translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
 }
  ngAfterViewInit(){
    document.getElementById("spinner_loader").style.display = "none";
  }

}

