
import {Injectable, Injector} from '@angular/core';
import 'rxjs/Rx';
import { environment} from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MyActivityService {
  public baseURL = environment.config.apiKey;
  constructor(private http: HttpClient) {}


  getActivitys():Observable<any>{
    const headers = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
    }
    return this.http.post(environment.config.h5plogin+'h5p','',headers);
    // return this.http.post('http://52.221.85.237/wordpress_h5p/index.php/wp-json/auth/v1/h5p','',headers);
  }
  getActivityList(id):Observable<any>{
    return this.http.get(this.baseURL+'/student/course/view_activity/'+id);
  }
  getStudentActivityAttendCount(id):Observable<any>{
    return this.http.get(this.baseURL+'/student/course/getStudentActivityAttendCount/'+id);
  }
  getStudentMarks(id):Observable<any>{
    return this.http.get(this.baseURL+'/student/course/getStudentMarks/'+id);
  }
  //removed course_id
  getActivityDescriptionList(embedId,sessionIds):Observable<any>{
    return this.http.get(this.baseURL+'/student/course/activity_description_list/'+embedId+'/'+sessionIds);
  }
  updateStudentActivity(id):Observable<any>{
    const headers = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8'),
    }
    const course_id = 'course_id=' + id;
    return this.http.post(this.baseURL + '/student/course/updateStudentActivity', course_id, headers);
  }
}
