import { BrowserModule, Title } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { routing } from "./app.routing";
import { AppComponent } from "./app.component";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppBootstrapModule } from "./app.bootstrap.module";
import { httpLoaderFactory } from "./core/common-service/httpLoader-factory";
import { AuthGuardService } from "./core/common-service/auth-guard.service";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { FormsModule } from "@angular/forms";
import { ProfileModule } from "./core/header/profile.module";
import { MyDatePickerModule } from "mydatepicker";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgxBraintreeModule } from "ngx-braintree";
import { MySurveyComponent } from "./modules/my-survey/my-survey.component";

import { NgxAnalyticsModule } from "ngx-analytics";
import { NgxAnalyticsGoogleAnalytics } from "ngx-analytics/ga";
import { GoogleAnalyticsService } from "./core/common-service/google-analytics.service";
import { StarRatingDirective } from "./core/common-directive/star-rating.directive";
import { LasLilasModule } from "./modules/laslilas.module";
import { GroupClassModule } from "./modules/group-class/group-class.module";
//import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { NgxStripeModule } from "ngx-stripe";

//import { StripePaymentComponent } from './stripe-payment/stripe-payment.component';

@NgModule({
  declarations: [AppComponent, MySurveyComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "las-lilas" }),
    routing,
    FormsModule,
    NgbModule.forRoot(),
    AppBootstrapModule,
    HttpClientModule,
    LasLilasModule,
    //AngularMultiSelectModule,
    GroupClassModule,
    MyDatePickerModule,
    NgxBraintreeModule,
    ProfileModule,
    BrowserAnimationsModule, // required animations module
    // added to imports
    NgxAnalyticsModule.forRoot([NgxAnalyticsGoogleAnalytics]),
    ToastrModule.forRoot(), // ToastrModule added
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxStripeModule.forRoot("pk_live_k3hsCDHj4GEKT16EK1B4sNAF00vkmE7biT")
  ],
  providers: [
    Title,
    GoogleAnalyticsService,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
