

export const DashboadCommonDictionary = {
"welcomeText":"Welcome to Spanish World",
"coursesOneLabel":"New Upcoming Courses",
"coursesOneImg":"assets/icon/upcommingcourses.png",
"coursesTwoLabel":"My Courses",
"coursesTwoImg":"assets/icon/schedule.png",
"coursesThreeLabel":"My Schedule",
"coursesThreeImg":"assets/icon/schedule.png",
"spanishImmersion":"Spanish Immersion",
"immersionPara":"In Spanish World we are commited to offer the learning experiance to our students, We are proud to give them the opportunity to have a real experiance in the Hispanic world and bring our cluture to them.",
"hispanicTitle":"We bring you to the Hispanic World!",
"hispanicWorldImg":"assets/icon/hispanicWorld.png",
"hispanicColOneTitle":"Time of courses",
"hispanicColOnePara":" Through us our students can enjoy a course with different duration (any time of the year and for any period of time) in spain or Latin America",
"hispanicColTwoTitle":"Karaoke Evening",
"hispanicColTowPara":"Spanish is fun! Every two month we have a Karaoke evening wher ypu can participate in karaoke or simple enjoy listening",
"hispanicColThreeTitle":"Team Dinner out",
"hispanicColThreePara":" At the end of each module. everyone in the team is offered the possibility to enjoy a conversational class with their teacher while testing the best Spanish",
"advertisement":"You can look for our next session and book in our Facebook Page, let's start reading!",
"modalTitle":"Which Class are you looking for ?",
"modalButton":"Let's Go"
};
