

export const GroupClassCommonDictionary = {
    "tabOneLabel":"New Upcoming Courses",
    "tabTwoLabel":"My Courses",
    "tabThreeLabel":"My Schedule",
    "practiceTestButton":"Practice test",
    "classNotesButton":"Class Notes",
    "viewActivitiesButton":"View Activities",
    "makeUpClassButton":"Make - up Class"
    }; 