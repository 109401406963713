import {Injectable} from '@angular/core';

import { NgxAnalytics } from 'ngx-analytics';

@Injectable()
export class GoogleAnalyticsService {
  constructor(public ngxAnalytics:NgxAnalytics) {
  }

  setAnalyticsResponse(actionValue,categoryValue,labelValue){
    this.ngxAnalytics.eventTrack.next({
      action: actionValue,
      properties: { category: categoryValue,
        label: labelValue},
    });
  }

  getAuthKey(){
    if(localStorage.getItem('studentAuthKey')){
      return localStorage.getItem('studentAuthKey');
    }
  }

  decodeAuthToken(token){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
}
