import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HeaderComponent } from "./header.component";
import { MyDatePickerModule } from "mydatepicker";
import { LasLilasModule } from "../../modules/laslilas.module";
import { CapitalizeFirstPipe } from "../first-name.filter";
import { NotificationComponent } from "./notification/notification.component";
import { StarRatingDirective } from "../common-directive/star-rating.directive";

export const routes = [
  { path: "", component: HeaderComponent, pathMatch: "full" }
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    NgbModule.forRoot(),
    MyDatePickerModule,
    LasLilasModule
  ]
})
export class ProfileModule {}
