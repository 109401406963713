import { Component, OnInit, ViewChild } from "@angular/core";
import { LandingService } from "./landing.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
  providers: [LandingService]
})
export class LandingComponent implements OnInit {
  constructor(
    private landingService: LandingService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  public data: any[] = [{ id: undefined, name: "Please select school" }];
  public schools = new Schools();
  @ViewChild("form") form: any;
  ngOnInit() {
    this.getSchools();
  }

  getSchools() {
    this.landingService.getSchools().subscribe(
      res => {
        console.log("schools", res.data);
        this.data = [...this.data].concat(res.data);
        // this.data.forEach(el => {
        //   this.schools.push(el.name);
        // });
        // console.log("schools array", this.schools);
      },
      error => {
        console.log(error);
      }
    );
  }
  submit(data) {
    console.log("submitted ", data);
    if (data !== undefined) {
      localStorage.setItem("schoolId", data);
      this.router.navigate(["/login"]);
    } else {
      this.toastr.error("Please select school !");
    }
  }
}

export class Schools {
  id: number;
}
