import {Component, OnChanges, ViewEncapsulation, ViewChild, TemplateRef, Input, Output} from '@angular/core';
import { Title }     from '@angular/platform-browser';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import {ActivatedRoute, NavigationExtras} from '@angular/router';
import {GroupClassCommonDictionary} from '../group-class-common-dictionary';
import {GroupClassService} from '../group-class.service';
import {DashboadCommonDictionary} from '../../dashboard/dashboard-common-dictionary';



const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#822b8a',
    secondary: '#822b8a'
  }
};
declare var $:any;
@Component({
  selector: 'app-my-course',
  templateUrl: './my-course.component.html',
  providers:[GroupClassService],
  encapsulation: ViewEncapsulation.None
})
export class MyCourseComponent implements OnChanges{
  @Input() ongoingClassResponseList;
  @Output() actionEmit;
  // public ongoingClassResponseList; // hold upcoming and Completed Response

  public backToCourseSection=false;
  public selectedOngoingItem;
  public dashboardText=DashboadCommonDictionary;
  public groupClassText=GroupClassCommonDictionary;
  public makeupClassListResponse;
  public class_service_type;
  selectedItem;
  public myCourseTab=[{"tabName":"Ongoing Course"},{"tabName":"Completed Course"}]; //,{"tabName":"Upcoming Session"} tabs 1) Ongoing Class ..2)Completed Class.. 3)Upcoming Class
  public onGoingSectionTab=[{"tabName":"Upcoming Session"},{"tabName":"Completed Session"}];

  constructor(public groupClassService:GroupClassService){
    this.selectedItem=this.myCourseTab[0];
  }

  ngOnInit(){
    this.getOngoingResponse('ONGOING');

  }
  ngOnChanges(data){
    console.log(data);
    this.selectedItem=this.myCourseTab[0];
  }
  public getOngoingResponse(key){
    this.groupClassService.getOngoingCourseList(key).subscribe(response=>{
      this.ongoingClassResponseList=response['data'];
    })
  }

  public getSelectedTabContainerResponse(item){

    this.selectedItem = item;
    switch (item.tabName) {
      case "Ongoing Course":
        this.getOngoingResponse('ONGOING');
        break;
      case "Completed Course":
        this.getOngoingResponse('COMPLETED');
        break;
      case "Upcoming Course":
        break;
    }
  }

  /**
   * @method getOngoingSection
   * @param item
   * @param click in mycouser section ongoing tab
   */
  public getOngoingSection(item){
    this.backToCourseSection=true;
    this.selectedOngoingItem=this.onGoingSectionTab[0];

    this.class_service_type=item.service_type;
    this.groupClassService.getSessinClassList(item.id).subscribe(response=>{
      // this.scheduleSessionClassList=response['data']['upcoming'];
      this.makeupClassListResponse=response['data'];

    })
  }
  public backToCourse(){
    this.backToCourseSection=false;
  }

  private selectedSesionTab(item){
    this.selectedOngoingItem = item;
    switch (item.tabName) {
      case "Upcoming Session":
        break;
      case "Completed Session":
        break;
    }

  }
}
