import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  AfterViewInit
} from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { Inject, HostListener } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ProfileService } from "./profile.service";
import { HttpErrorResponse } from "@angular/common/http";
import { Profile } from "./profile.model";
import { IMyDpOptions, IMyDateModel } from "mydatepicker";
import { ToastrService } from "ngx-toastr";
import { GroupClassService } from "../../modules/group-class/group-class.service";
import { NotificationComponent } from "./notification/notification.component";
import * as Stripe from "ngx-stripe";
import Swal from "sweetalert2";
import { Elements, Element as StripeElement, StripeService } from "ngx-stripe";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [ProfileService, GroupClassService],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit {
  displayRatingScore = 0;
  public isStricky: boolean = false;
  public isUserLoginIn;
  public schoolId;
  public profile: any[] = [];
  public display_mode: boolean = false;
  public edit_mode: boolean = false;
  public buttonName: any = "Edit";
  public nationalityList;
  public countryList;
  public timeZoneListList;
  public Updateprofile;
  public isLogedIn: boolean = false;
  public genderOption = ["MALE", "FEMALE"];
  public isValidImage = true;
  public isSaveProfileImg = false;
  public uploadedFile;
  public selectedDOB;
  public studentDOB;
  public selectedRating;
  public ratingMessage;
  public notificationList;
  @ViewChild(NotificationComponent) child: NotificationComponent;
  public myDatePickerOptions: IMyDpOptions = {
    // other options...
    dateFormat: "d-m-yyyy"
  };
  authToken: string;
  //showBool: boolean = true;

  // Notification Variable
  elements: Elements;
  card: StripeElement;
  @ViewChild("card") cardRef: ElementRef;
  //displayRatingScore = 0;

  public total_amount = "";
  public stripeTest: FormGroup;
  course_id: any;

  constructor(
    public router: Router,
    @Inject(DOCUMENT) private doc: Document,
    private elementRef: ElementRef,
    private profileService: ProfileService,
    public toastrService: ToastrService,
    public groupClassService: GroupClassService,

    private stripeService: StripeService,
    private toastr: ToastrService,
    public fb: FormBuilder
  ) {
    this.schoolId = localStorage.getItem("schoolId");
    this.authToken = localStorage.getItem("studentAuthKey");
    this.mountCard();
  }
  ngOnInit() {
    this.Updateprofile = new Profile();
    this.isUserLoginIn = localStorage.getItem("loginInDetail");

    this.isLogedInUser();
    this.getNotification();
    // Stripe Card initialize
    this.stripeTest = this.fb.group({
      name: ["", [Validators.required]]
    });
  }

  private isLogedInUser() {
    if (!localStorage.getItem("studentAuthKey")) {
      this.isLogedIn = true;
    } else {
      this.isLogedIn = false;
      this.getUser();
      this.getNationalityList();
      this.getCountry();
    }
  }
  /**
   *
   * @description its add stricky class to nav once scroll down to 50px
   * @memberof HeaderComponent
   */
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let num = this.doc.lastElementChild.scrollTop;
    if (num > 50) {
      this.isStricky = true;
    } else if (this.isStricky && num < 5) {
      this.isStricky = false;
    }
  }
  ngAfterViewInit() {
    this.toggleUserNotificationWrapper();
    this.toggleUserProfileWrapper();
    // if (this.router.url == "/landing") {
    //   this.showBool = false;
    // } else {
    //   this.showBool = true;
    // }
  }
  private toggleUserNotificationWrapper() {
    $("html").click(function() {
      $(".user-notification").hide();
    });
    $(".my-notification-item").click(function(e) {
      e.stopPropagation();
    });
    $(".my-notification").click(function(e) {
      $(".user-notification").toggle();
      $(".user-profile").hide();
    });
  }

  /**
   @method toggleUserProfileWrapper()
   @description toggle profile Container on click and hide ouside click
   */
  private toggleUserProfileWrapper() {
    $("html").click(function() {
      $(".user-profile").hide();
    });
    $(".my-profile-item").click(function(e) {
      e.stopPropagation();
    });
    $(".user-avtar").click(function(e) {
      $(".user-profile").toggle();
      //  $('.my-profile-item').toggleClass('show-dropdown');
      $(".user-notification").hide();
    });
  }

  /**
  @method getUser()
   @description get Student Profile Detail
 */
  getUser() {
    this.profileService.getProfile().subscribe(
      response => {
        this.profile = response.data;
        const formattedDobArr = response.data.dob.split("/");
        localStorage.setItem("email", response.data.email);
        // this.profile['dob'] = formattedDobArr[2] + '-' + formattedDobArr[1] + '-' + formattedDobArr[0];
        this.studentDOB = response.data.dob;
        if (response.data.dob) {
          this.profile["dob"] = {
            date: {
              day: formattedDobArr[0],
              month: formattedDobArr[1],
              year: formattedDobArr[2]
            }
          };
        } else {
          this.profile["dob"] = "";
        }
      },
      (err: HttpErrorResponse) => {
        console.log("error : ", err);
      }
    );
  }

  onDateChanged(event: IMyDateModel): void {
    this.selectedDOB = event.formatted;
  }

  /**
   @method getNationalityList()
   @description get Nationality List
   */
  private getNationalityList() {
    this.profileService.getNationality().subscribe(
      response => {
        this.nationalityList = response.data;
      },
      (err: HttpErrorResponse) => {
        console.log("login err response: ", err);
      }
    );
  }

  /**
   @method getCountry()
   @description get Country List
   */
  public getCountry() {
    this.profileService.getCountry().subscribe(
      response => {
        this.countryList = response.data;
      },
      (err: HttpErrorResponse) => {
        console.log("login err response: ", err);
      }
    );
  }

  /**
   @method getTimeZone()
   @param code
   @description get time zone based on country code
   */
  public getTimeZone(code) {
    this.profileService.getTimeZone(code).subscribe(
      response => {
        this.timeZoneListList = response.data;
      },
      (err: HttpErrorResponse) => {
        console.log("login err response: ", err);
      }
    );
  }

  /**
   @method updateUserProfile()
   @param ngModel
   @description Update Profile detail
   */
  public updateUserProfile(ngModel) {
    const dob =
      ngModel["dob"]["date"].day +
      "/" +
      ngModel["dob"]["date"].month +
      "/" +
      ngModel["dob"]["date"].year;

    const postParam = {
      name: ngModel.name,
      mobile: ngModel.mobile,
      country_code: ngModel.country_code,
      dob: dob,
      gender: ngModel.gender,
      occupation: ngModel.occupation,
      nationality: ngModel.nationality,
      zone_id: ngModel.zone_id
      //school_location  choosen from landing page
      // school_location: "Laslilas Singapore" // To be changed later
    };
    this.profileService.updateProfile(postParam).subscribe(
      response => {
        this.toastrService.success(response.message, "Success", {
          positionClass: "toast-bottom-right"
        });
        this.getUser();
        $("#myProfile").modal("hide");
        this.toggleMode();
      },
      (err: HttpErrorResponse) => {
        console.log("login err response: ", err);
      }
    );
  }

  /**
   @method logOut()
   @description once click on logout button it will remove token from localstorage and route to home
   */
  public logOut() {
    if (localStorage.getItem("studentAuthKey")) {
      localStorage.removeItem("studentAuthKey");
      localStorage.clear();
      this.ngAfterViewInit();
      this.isLogedIn = true;
      this.toastrService.success("Logout successfully", "Success", {
        positionClass: "toast-bottom-right"
      });
      this.router.navigate(["/"]);
    }
  }

  /**
   @method routeLogin()
   @description once click on loin button it will route to login page
   */
  public routeLogin() {
    this.router.navigate(["/landing"]);
  }
  toggleMode() {
    this.edit_mode = !this.edit_mode;
    this.display_mode = !this.display_mode;
    $("#myProfile").modal("show");
    this.Updateprofile = Object.assign({}, this.profile);
    this.getTimeZone(this.profile["country_code"]);
  }

  /**
   @method selectImage()
   @param imageInput
   @description get Selected Img and check img type bind to ngModel
   */
  public selectImage(imageInput) {
    this.isValidImage = false;
    if (
      imageInput.files.length &&
      imageInput.files[0].type !== "image/jpg" &&
      imageInput.files[0].type !== "image/png" &&
      imageInput.files[0].type !== "image/jpeg"
    ) {
      this.isValidImage = false;
      return;
    }
    this.isValidImage = true;
    if (imageInput.files.length) {
      const reader = new FileReader();
      this.uploadedFile = imageInput.files[0];
      const file = imageInput.files[0];
      reader.onload = () => {
        this.profile["profile_image_url"] = reader.result;
        this.Updateprofile.profile_image_url = reader.result;
        this.isSaveProfileImg = true;
        //this.uploadProfileImage(imageInput.files[0]);
      };
      reader.readAsDataURL(file);
    }
  }

  /**
   @method uploadProfilePhoto()
   @description upload Selected Photo to DB
   */
  public uploadProfilePhoto() {
    this.uploadProfileImage(this.uploadedFile);
  }
  private uploadProfileImage(img) {
    const fd = new FormData();
    fd.append("file", img);
    this.profileService.uploadProfileImage(fd).subscribe(
      response => {
        this.Updateprofile.profile_image_url = response["data"];
        this.toastrService.success(response.message, "Success", {
          positionClass: "toast-bottom-right"
        });
        this.isSaveProfileImg = false;
      },
      (err: HttpErrorResponse) => {
        console.log("login err response: ", err);
      }
    );
  }
  public closeProfileModal() {
    $("#myProfile").modal("hide");
  }
  public submitGivenRating(score) {
    this.selectedRating = score;
  }
  public closeRatingModal() {
    localStorage.removeItem("notification_id");
    $("#rating-modal-type").modal("hide");
  }

  public saveGivenRating() {
    if (localStorage.getItem("notification_id")) {
      let notification_id = localStorage.getItem("notification_id");
      $("#rating-modal-type").modal("hide");
      console.log(this.selectedRating + this.ratingMessage);
      let postParam = {
        slot_id: notification_id,
        rating: this.selectedRating ? this.selectedRating : 0,
        comment: this.ratingMessage ? this.ratingMessage : ""
      };
      this.postRating(postParam);
    }
  }
  public postRating(postParam) {
    this.groupClassService.saveClassRating(postParam).subscribe(
      response => {
        this.toastrService.success("Thanks for rating", "Success", {
          positionClass: "toast-bottom-right"
        });
        this.selectedRating = 0;
        this.child.getNotification();
        localStorage.removeItem("notification_id");
        $("#rating-modal-type").modal("hide");
      },
      (err: HttpErrorResponse) => {
        this.toastrService.error(err.error.message, "Error", {
          positionClass: "toast-bottom-right"
        });
      }
    );
  }

  // Notification Function
  public getNotification() {
    this.profileService.getNotification().subscribe(
      response => {
        this.notificationList = response.data.data;
      },
      (err: HttpErrorResponse) => {
        console.log("login err response: ", err);
      }
    );
  }
  ratingOpen(id) {
    // event.stopPropogation();
    localStorage.setItem("notification_id", id);
    $("#rating-modal-type").modal("show");
  }

  payNextModule(id){
    console.log("pay next ",id);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        type: 'GROUP',
        id: id }
    };
    this.router.navigate(['/class'],navigationExtras);
  }

  public showPaid(notification) {
    if (
      notification.opcode == "312" &&
      notification.service_type == "PRIVATE" &&
      notification.action_completed == "1"
    ) {
      return true;
    } else {
      return false;
    }
  }
  public showPay(notification) {
    if (
      notification.opcode == "312" &&
      notification.service_type == "PRIVATE" &&
      notification.action_completed == "0"
    ) {
      return true;
    } else {
      return false;
    }
  }
  public mountCard() {
    this.stripeService.elements().subscribe(elements => {
      this.elements = elements;
      // Only mount the element the first time
      if (true) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#666EE8",
              color: "#31325F",
              lineHeight: "40px",
              fontWeight: 300,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: "18px",
              "::placeholder": {
                color: "#CFD7E0"
              }
            }
          }
        });
        if (this.cardRef && this.cardRef.nativeElement) {
          this.card.mount(this.cardRef.nativeElement);
        }
      }
    });
  }
  public openPayment(e, data) {
    if (data && data.course_amount) {
      this.total_amount = data.course_amount;
      this.course_id = data.course_id;
    }
    console.log("DATA", data);
    e.stopPropagation();
    this.mountCard();
    $("#payment-box").modal("show");
  }
  public buy() {
    const name = this.stripeTest.get("name").value;
    console.log("data", this.card);
    this.stripeService.createToken(this.card, { name }).subscribe(token => {
      console.log("Token created correctly", token);

      const data = {
        token: token.token.id,
        amount: this.total_amount
      };
      this.groupClassService.postStripe(data).subscribe(
        res => {
          console.log("post payment", res);
          if (res["data"].status == "succeeded") {
            const data = {
              response: res["data"],
              discount_code: "",
              payable_amount: this.total_amount,
              total_amount: this.total_amount,
              course_id: this.course_id
            };
            this.groupClassService.postStripeResponse(data).subscribe(
              res => {
                console.log("post stripe res", res);
                $("#payment-box").modal("hide");
                Swal("Payment Successful!", "", "success");
                this.getNotification();
              },
              error => {
                console.log(error);
                this.toastr.error("", error.error.message);
              }
            );
          }
        },
        (err: HttpErrorResponse) => {
          console.log(err);
          this.toastr.error("", err.error.error.message);
        }
      );
    });
  }

  // goHome() {
  //   if (this.schoolId && this.authToken) {
  //     this.router.navigate(["/home"]);
  //   } else {
  //     this.router.navigate(["/landing"]);
  //   }
  // }
}
