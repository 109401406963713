import {Component, OnInit, ViewEncapsulation, ElementRef, Input} from '@angular/core';


declare var $: any;

@Component({
  selector: 'app-my-profile',
  templateUrl: './view-profile.component.html',
  // styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewProfileComponent implements OnInit {
  @Input() profileDetailResponse:any;
  @Input() studentDOBKey:any;
  constructor(){}
  ngOnInit(){}


}
