import { Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-laslilas',
  templateUrl: './laslilas.component.html',
//   styleUrls: ['./laslilas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LasLilasComponent {

  constructor(router: Router) {}
  ngAfterViewInit(){}

}
