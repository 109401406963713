import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpHeaders
} from "@angular/common/http";
// import { AuthService } from './auth.service';
import { Observable } from "rxjs";
import { map, filter, tap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const studentAuthKey = localStorage.getItem("studentAuthKey");
    const schoolId = localStorage.getItem("schoolId");

    document.getElementById("spinner_loader").style.display = "block";
    let requestKey: HttpRequest<any>;
    console.log(request);
    if (request.url.endsWith("/payment/client-token")) {
      return next.handle(request).pipe(
        tap(
          event => {
            if (event instanceof HttpResponse) {
              document.getElementById("spinner_loader").style.display = "none";
            }
          },
          error => {
            document.getElementById("spinner_loader").style.display = "none";
          }
        )
      );
      //   let headers = new HttpHeaders().set('Authorization', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijg3IiwiY3JlYXRlZF9vbiI6IjAwMDAtMDAtMDAgMDA6MDA6MDAiLCJjcmVhdGVkX2J5IjoiMCIsInVwZGF0ZWRfb24iOiIwMDAwLTAwLTAwIDAwOjAwOjAwIiwidXBkYXRlZF9ieSI6Ijg3IiwibmFtZSI6IlByaXlhIiwiZW1haWwiOiJwcml5YW5rYS5taXNocmFAaWdsdWxhYnMuY29tIiwicGFzc3dvcmQiOiIzNDNiMWM0YTNlYTcyMWIyZDY0MGZjODcwMGRiMGYzNiIsIm1vYmlsZSI6Ijk2MjAzNDgwMzUiLCJvdHAiOm51bGwsIm90cF9leHBpcmF0aW9uX3RpbWUiOm51bGwsInVzZXJfZ3JvdXAiOiIzIiwiaXNfdmVyaWZpZWQiOiIxIiwiY291bnRyeV9jb2RlIjoiSU4iLCJ6b25lX2lkIjoiMTk0IiwiZm9yZ290dGVuX3Bhc3N3b3JkX2NvZGUiOm51bGwsImZvcmdvdHRlbl9wYXNzd29yZF90aW1lIjpudWxsLCJkb2IiOiIxOTkyLTAxLTE4IiwiZ2VuZGVyIjoiRkVNQUxFIiwib2NjdXBhdGlvbiI6IlRlc3RlciIsIm5hdGlvbmFsaXR5IjoiODYiLCJhZGRyZXNzIjpudWxsLCJzY2hvb2xfbG9jYXRpb24iOiJMYXNsaWxhcy1TaW5nYXBvcmUiLCJwb3N0YWxfY29kZSI6bnVsbCwiY2l0eSI6bnVsbCwibGF0aXR1ZGUiOm51bGwsImxvbmdpdHVkZSI6bnVsbCwicHJvZmlsZV9pbWFnZV91cmwiOiJodHRwczpcL1wvczMuYW1hem9uYXdzLmNvbVwvbGFzLWxpbGFzLXRlc3RcL29yaWdpbmFsXC9pbWFnZXNcL2c4WXZXNG5EdUgxZFNYbS5qcGciLCJzdGF0dXMiOiIxIiwicmVnaXN0cmF0aW9uX2lkIjoiIiwicmVtYXJrcyI6bnVsbH0.2hrwWXL23KC3QmRbaKWQNH0YEVtj9uIcgpx12x5sWMM');
      //   headers = headers.set('course_id', '28');
      //   headers = headers.set('Content-Type','application/json');

      //   const httpOptions = {
      //             headers: new HttpHeaders({
      //                 'Content-Type':  'application/json',
      //                     'Authorization':'dasdas'
      //      })
      // };
      //   requestKey = request.clone({headers});
      //   {'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijg3IiwiY3JlYXRlZF9vbiI6IjAwMDAtMDAtMDAgMDA6MDA6MDAiLCJjcmVhdGVkX2J5IjoiMCIsInVwZGF0ZWRfb24iOiIwMDAwLTAwLTAwIDAwOjAwOjAwIiwidXBkYXRlZF9ieSI6Ijg3IiwibmFtZSI6IlByaXlhIiwiZW1haWwiOiJwcml5YW5rYS5taXNocmFAaWdsdWxhYnMuY29tIiwicGFzc3dvcmQiOiIzNDNiMWM0YTNlYTcyMWIyZDY0MGZjODcwMGRiMGYzNiIsIm1vYmlsZSI6Ijk2MjAzNDgwMzUiLCJvdHAiOm51bGwsIm90cF9leHBpcmF0aW9uX3RpbWUiOm51bGwsInVzZXJfZ3JvdXAiOiIzIiwiaXNfdmVyaWZpZWQiOiIxIiwiY291bnRyeV9jb2RlIjoiSU4iLCJ6b25lX2lkIjoiMTk0IiwiZm9yZ290dGVuX3Bhc3N3b3JkX2NvZGUiOm51bGwsImZvcmdvdHRlbl9wYXNzd29yZF90aW1lIjpudWxsLCJkb2IiOiIxOTkyLTAxLTE4IiwiZ2VuZGVyIjoiRkVNQUxFIiwib2NjdXBhdGlvbiI6IlRlc3RlciIsIm5hdGlvbmFsaXR5IjoiODYiLCJhZGRyZXNzIjpudWxsLCJzY2hvb2xfbG9jYXRpb24iOiJMYXNsaWxhcy1TaW5nYXBvcmUiLCJwb3N0YWxfY29kZSI6bnVsbCwiY2l0eSI6bnVsbCwibGF0aXR1ZGUiOm51bGwsImxvbmdpdHVkZSI6bnVsbCwicHJvZmlsZV9pbWFnZV91cmwiOiJodHRwczpcL1wvczMuYW1hem9uYXdzLmNvbVwvbGFzLWxpbGFzLXRlc3RcL29yaWdpbmFsXC9pbWFnZXNcL2c4WXZXNG5EdUgxZFNYbS5qcGciLCJzdGF0dXMiOiIxIiwicmVnaXN0cmF0aW9uX2lkIjoiIiwicmVtYXJrcyI6bnVsbH0.2hrwWXL23KC3QmRbaKWQNH0YEVtj9uIcgpx12x5sWMM',
      //     'course_id': '28'}});

      // let headers = new HttpHeaders();
      // headers = request.headers.set("Authorization","eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Ijg3IiwiY3JlYXRlZF9vbiI6IjAwMDAtMDAtMDAgMDA6MDA6MDAiLCJjcmVhdGVkX2J5IjoiMCIsInVwZGF0ZWRfb24iOiIwMDAwLTAwLTAwIDAwOjAwOjAwIiwidXBkYXRlZF9ieSI6Ijg3IiwibmFtZSI6IlByaXlhIiwiZW1haWwiOiJwcml5YW5rYS5taXNocmFAaWdsdWxhYnMuY29tIiwicGFzc3dvcmQiOiIzNDNiMWM0YTNlYTcyMWIyZDY0MGZjODcwMGRiMGYzNiIsIm1vYmlsZSI6Ijk2MjAzNDgwMzUiLCJvdHAiOm51bGwsIm90cF9leHBpcmF0aW9uX3RpbWUiOm51bGwsInVzZXJfZ3JvdXAiOiIzIiwiaXNfdmVyaWZpZWQiOiIxIiwiY291bnRyeV9jb2RlIjoiSU4iLCJ6b25lX2lkIjoiMTk0IiwiZm9yZ290dGVuX3Bhc3N3b3JkX2NvZGUiOm51bGwsImZvcmdvdHRlbl9wYXNzd29yZF90aW1lIjpudWxsLCJkb2IiOiIxOTkyLTAxLTE4IiwiZ2VuZGVyIjoiRkVNQUxFIiwib2NjdXBhdGlvbiI6IlRlc3RlciIsIm5hdGlvbmFsaXR5IjoiODYiLCJhZGRyZXNzIjpudWxsLCJzY2hvb2xfbG9jYXRpb24iOiJMYXNsaWxhcy1TaW5nYXBvcmUiLCJwb3N0YWxfY29kZSI6bnVsbCwiY2l0eSI6bnVsbCwibGF0aXR1ZGUiOm51bGwsImxvbmdpdHVkZSI6bnVsbCwicHJvZmlsZV9pbWFnZV91cmwiOiJodHRwczpcL1wvczMuYW1hem9uYXdzLmNvbVwvbGFzLWxpbGFzLXRlc3RcL29yaWdpbmFsXC9pbWFnZXNcL2c4WXZXNG5EdUgxZFNYbS5qcGciLCJzdGF0dXMiOiIxIiwicmVnaXN0cmF0aW9uX2lkIjoiIiwicmVtYXJrcyI6bnVsbH0.2hrwWXL23KC3QmRbaKWQNH0YEVtj9uIcgpx12x5sWMM")
      //                         .set("course_id", '28');
      // //                           .delete("Authorization", environment["Authorization"])
      // //                           .delete("PD-GROUP-ID", environment["PD-GROUP-ID"])
      // //                           .delete("PD-DEVICE", environment["PD-DEVICE"])
      // //                           .delete("PD-TIME", dateInMillis);
    } else {
      if (request.url.endsWith("/schools")) {
        requestKey = request.clone();
      } else if (
        request.url.endsWith("/auth/login") ||
        request.url.endsWith("/auth/register") ||
        request.url.endsWith("/auth/forgot_password") ||
        request.url.endsWith("/auth/resend_otp") ||
        request.url.endsWith("/auth/reset_password") ||
        request.url.endsWith("/auth/verify_otp")
      ) {
        //removed LS-SCHOOL-ID on h5p
        if( request.url.includes('h5p')) {

        }else{
        requestKey = request.clone({
          setHeaders: {
            "LS-SCHOOL-ID": schoolId ? schoolId : ""
          }
        });
      }
      } else {
        
        //removed LS-SCHOOL-ID on h5p
        if( request.url.includes('h5p')) {
          requestKey = request.clone({
            setHeaders: {
              Authorization: studentAuthKey ? studentAuthKey : ""
            }
          });

        }else{
        requestKey = request.clone({
          setHeaders: {
            Authorization: studentAuthKey ? studentAuthKey : "",
            "LS-SCHOOL-ID": schoolId ? schoolId : ""
          }
        });
      }
      }
    }

    return next.handle(requestKey).pipe(
      tap(
        event => {
          if (event instanceof HttpResponse) {
            document.getElementById("spinner_loader").style.display = "none";
          }
        },
        error => {
          document.getElementById("spinner_loader").style.display = "none";
        }
      )
    );
  }
}
