import {Component, Input, Pipe, PipeTransform, ViewEncapsulation} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {MyActivityService} from './my-activity.service';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpErrorResponse} from '@angular/common/http';
import {MyActivityModel} from './my-activity.model';
import {GoogleAnalyticsService} from '../../core/common-service/google-analytics.service';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    console.log(value)
     return this.sanitized.bypassSecurityTrustUrl(value);
  }
}

@Pipe({ name: 'SafeResourceUrl'})
export class SafeResourceUrl implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
   
     return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}


declare var $:any;
@Component({
  selector: 'app-my-activity',
  templateUrl: './my-activity.component.html',
  styleUrls: ['./my-activity.component.scss'],
  providers: [MyActivityService],
  encapsulation: ViewEncapsulation.Emulated
})
export class MyActivityComponent  {
  public activityListResponse;
  public reDoActivity;
  public countOfActivity;
  public studentMark;
  public activityListResponseCopy;
  emailId:any;
  public descriptionList;
  public hspArrayEmbedId=[];
  public testUrl=[];
  public selectedH5PResponse=[];
  public hspArray=[];
  public description=[];
  public hspArrayHtml=[];
  public hspArraySession=[];
  public myActivityModel=new MyActivityModel(null);
  public testActivitiesArray = [];
  public sessionTextFlag = {};
  public textFlag:boolean = false;
  urlH5p:any;
  @Input() selectedSession:any;
  pager = {
    index: 0,
    size: 1,
    count: 1
  };

  constructor(
    public googleAnalyticsService:GoogleAnalyticsService,
    public myActivityService:MyActivityService,
    private _title: Title,
    
  ) {
   
    // this.getActivityList();
    
  }

  ngOnInit() {
    this.emailId=localStorage.getItem("email")
    this.getStudentActivityAttendCount(this.selectedSession['id']);
    this.getStudentMarks();
    // this.checkSessionText(0);   
  }
  ngOnChanges(){
    
    this.myActivityService.getActivityList(this.selectedSession['id']).subscribe(response=>{
      // document.cookie = "course_id="+response['data']['course_id']+";path=/wordpress_h5p/";
      // document.cookie = "token_Authorization="+localStorage.getItem('studentAuthKey')+";path=/wordpress_h5p/";
      
      this.activityListResponse= response['data'];
       this.myActivityModel=new MyActivityModel(response['data']);
      this.pager.count = this.activityListResponse['arr_course_activities'].length;
      this.hspArray = this.activityListResponse['arr_course_activities'];
      for(let i=0;i<this.hspArray.length;i++){
        // console.log(this.hspArray[i].id);
        // document.cookie = "activity_id="+this.hspArray[i].id+";path=/wordpress_h5p/";
        // let url = "http://test.iglulabs.com/wordpress_h5p/testh5p/?email="+this.emailId+"&id="+this.hspArray[i].id;
        let url = this.hspArray[i].embed_link_new;
        let session = this.hspArray[i].session;
        // console.log(this.urlH5p)
        // this.hspArrayHtml.unshift(url);

      var n = this.hspArray[i].embed_link.lastIndexOf("&id=");
      var res = this.hspArray[i].embed_link.slice(n+4, 106);
      var embedId = parseInt(res, 10);
      console.log(embedId)
      this.hspArrayEmbedId.unshift(embedId);
      this.hspArrayHtml.unshift(url);
      this.hspArraySession.unshift(session);
    }

    // var array = this.hspArrayEmbedId.reverse();
    var arrySession=this.hspArraySession;

    var SessionVal=arrySession.toString(); 
    var sessionIds=SessionVal.replace(/,/g, '-');
    var array = this.hspArrayEmbedId;
    var val=array.toString(); 
    var ids=val.replace(/,/g, '-');
    //removed response['data']['course_id']
    this.myActivityService.getActivityDescriptionList(ids,sessionIds).subscribe(response=>{
      // this.scheduleSessionClassList=response['data']['upcoming'];
      // this.makeupClassListResponse=response['data'];

      this.description=response['data']['description'];
      // this.descriptionList=this.description.reverse();
      this.descriptionList=this.description;

      this.setSessionTextFlag();



    })
    this.testUrl=this.hspArrayHtml;
    this.hspArray = this.hspArray.reverse();
    // set cookie to activity id to 1st element in h5parray
    // console.log(this.hspArray[0].id);
      // document.cookie = "activity_id="+this.hspArray[0].id+";path=/wordpress_h5p/";

    this.testActivitiesArray = this.hspArray;
      this.setGoogleAnalyticInfo(this.pager.count,this.activityListResponse);
       this.getSingleObject();
       this.emailId=localStorage.getItem("email")
   
    },(err:HttpErrorResponse)=>{
      console.log(err);
    });




  }

 
  public setSessionTextFlag(){
    for(var x in this.descriptionList){
          if(this.sessionTextFlag["session"+this.descriptionList[x]['session']] == undefined){
              this.descriptionList[x]["sessionTextFlag"] = true;
              this.sessionTextFlag["session"+this.descriptionList[x]['session']] = this.descriptionList[x]['session'];
            }
    }

  }

  setGoogleAnalyticInfo(count,object){
   if(count>0){
    let authToken=this.googleAnalyticsService.getAuthKey();
    if(authToken){
      let decodedToken=this.googleAnalyticsService.decodeAuthToken(authToken);
      this.googleAnalyticsService.setAnalyticsResponse(decodedToken.email,'H5P',object.course_name);
    }
   }
  }

  public getSingleObject(){

   if(this.testUrl){
    this.hspArray = this.testActivitiesArray.slice(this.pager.index, this.pager.index + this.pager.size)
    // set cookie to activity id to 1st element in h5parray
      // document.cookie = "activity_id="+this.hspArray[0].id+";path=/wordpress_h5p/";


    return this.hspArrayHtml=this.testUrl.slice(this.pager.index, this.pager.index + this.pager.size)
   } else {
     return [];
   }
    // return (this.testUrl) ?
    //    //this.selectedH5PResponse=this.myActivityModel['arr_course_activities'].slice(this.pager.index, this.pager.index + this.pager.size) : [];
    //   this.hspArrayHtml=this.testUrl.slice(this.pager.index, this.pager.index + this.pager.size) : [];
  }
  goTo(index: number) {
    if (index >= 0 && index < this.pager.count) {
      this.pager.index = index;
      this.getSingleObject();
    }
  }
  public getActivityListCopy(activityListRes){
    this.myActivityService.getActivityList(this.selectedSession['id']).subscribe(response=>{
      this.activityListResponseCopy= response['data'];
      this.getStudentActivityAttendCount(this.selectedSession['id']);   
    },(err:HttpErrorResponse)=>{
      console.log(err);
    });
  }
  public getStudentActivityAttendCount(Course_id){
    this.myActivityService.getStudentActivityAttendCount(Course_id).subscribe(response=>{
      this.countOfActivity= response['data'].length; 
    },(err:HttpErrorResponse)=>{
      console.log(err);
    });
  }

  public getStudentMarks(){
    this.myActivityService.getStudentMarks(this.selectedSession['id']).subscribe(response=>{
      this.studentMark= response['data']['student_mark'][0]['total']; 
      // console.log(this.studentMark['student_mark'][0]['total']);  
    },(err:HttpErrorResponse)=>{
      console.log(err);
    });
  }
  public reDo(){
    this.myActivityService.updateStudentActivity(this.selectedSession['id']).subscribe(response=>{
      this.reDoActivity= response['data']; 
      // console.log(this.countOfActivity);  
      this.goTo(0);
      // this.myActivityService.getActivityList(this.selectedSession['id'])

    this.getActivityListCopy(this.selectedSession['id']);
    },(err:HttpErrorResponse)=>{
      console.log(err);
    });
  }

}
